import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes } from 'utils/routeConfig';
import PrivateRoute from 'components/PrivateRoute';
import './translations';
import env from 'config/app.config';
import { createContext, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Initiate Context
export const ZaloInfoContext = createContext<any>({});
// Provide Context

export const ZaloInfoContextProvider = ({ children }: any) => {
  const [searchParamsZalo, setSearchParamsZalo] = useState();
  return (
    <ZaloInfoContext.Provider value={{ searchParamsZalo, setSearchParamsZalo }}>
      {children}
    </ZaloInfoContext.Provider>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ZaloInfoContextProvider>
        <Routes>
          {routes.map(route =>
            route.checkLogin ? (
              <Route
                key={route.key}
                path={`:appName${route.path}`}
                element={
                  <PrivateRoute pageTitle={route.pageTitle}>
                    <route.component />
                  </PrivateRoute>
                }
              />
            ) : (
              <Route
                key={route.key}
                path={`:appName${route.path}`}
                element={
                  <div>
                    <route.component />
                  </div>
                }
              />
            ),
          )}
        </Routes>
      </ZaloInfoContextProvider>
    </BrowserRouter>
  );
}

export default App;
