export default {
  'termAndCondition.title': 'Starbucks Vietnam',
  'termAndCondition.title.confirm': 'Are you a Starbucks Rewards™ member?',
  'termAndCondition.yes': 'Yes • Sign in',
  'termAndCondition.no': 'No • Join Starbucks Rewards™',
  'termAndCondition.title.term2': 'I agree to Starbucks Vietnam ',
  'termAndCondition.title.term3': 'Privacy Policy',
  'termAndCondition.title.term4': 'and',
  'termAndCondition.title.term5': 'Terms of Use',
  'termAndCondition.title.term6': '.',
  'termAndCondition.title.privacy': 'Privacy Policy',
  'termAndCondition.title.term': 'Terms of Use',
  'termAndCondition.content1':
    'PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR PARTICIPATING IN THE STARBUCKS REWARDS™ PROGRAM OR USING THE STARBUCKS CARDS, YOU AGREE TO BE BOUND BY THESE TERMS OF USE AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT PARTICIPATE IN THE STARBUCKS REWARDS™ PROGRAM AND DO NOT USE THE STARBUCKS CARDS.',
  'termAndCondition.content2':
    'These Terms of Use apply to your access to, and use of, all or part of any website or mobile application, and participation in, the Starbucks Rewards™ Program which is operated by Viet Idea Food & Beverages Company Limited (“Starbucks”), a licensee of Starbucks Corporation in Vietnam. These Terms of Use do not alter in any way the terms or conditions of any other agreement you may have with Starbucks for other products and services. Starbucks reserves the right to change, modify and/or eliminate Starbucks Rewards™ Program and/or all or any portion of these Terms of Use or any policy, FAQs, or guideline pertaining to Starbucks Rewards™ Program at any time and in its sole discretion. Any changes or modifications will be effective immediately upon posting and notifying the revisions to ',
  'termAndCondition.content3': ` or mobile application “Starbucks Vietnam” (“Mobile Application”). Your participation in Starbucks Rewards™ Program or use of the Starbucks Cards confirms your acceptance of these Terms of Use and any such changes or modifications; therefore, you should review these Terms of Use and applicable policies and FAQs frequently to understand the terms and conditions that apply to Starbucks Rewards™ Program and the Starbucks Cards. If you do not agree to these Terms of Use, you must stop participating in Starbucks Rewards™ Program and using the Starbucks Cards.`,
  'termAndCondition.content4': `The Starbucks Rewards™ Program is intended for personal use only. Commercial use is prohibited. This program is not targeted towards, nor intended for use by, anyone under the age of 18.`,
  'termAndCondition.content5': `Please read the Privacy Statement available at `,
  'termAndCondition.content6': `  carefully to understand how Starbucks collects, uses and discloses information about customers and how to update or change your personal information and how we communicate with you.`,
  'termAndCondition.content7': `Starbucks Rewards™ Program is one way in which Starbucks endeavors to reward and thank loyal customers for patronizing our business, and purchasing Starbucks products. Members of Starbucks Rewards™ Program are able to earn and accumulate “Stars” that can be redeemed for Starbucks Rewards™ Program’s benefits, also known as “Rewards”, at Starbucks stores in Vietnam.`,
  'termAndCondition.content8': `Starbucks Card`,
  'termAndCondition.content9': `The Starbucks Card refers to a physical Starbucks branded plastic card or a Starbucks branded visual card (or E-Card) that is a reusable gift card that customers can load money onto and only use to make purchases at Starbucks stores. A Starbucks Card shall be identified by its own card number, security code (CSC) and barcode. The Starbucks Card is valid only in Vietnam (all Starbucks stores in Vietnam), and must be activated at the minimum value of VND100,000 per card for the first use at the any time. If you want to purchase Starbucks Cards as a gift, they just need to be activated. Once you activate a Starbucks Card, it can be used to make purchases just as you would use cash however the Starbucks Card is non-refundable as well as not exchangeable for cash or credit balance. Normally, you can check your Starbucks Card balance by asking the barista at the any Starbucks store, and at `,
  'termAndCondition.content10': ` or the Mobile Application if you have registered to join the Starbucks Reward™ program. When the balance gets low, the Starbucks Card can be reloaded with for further purchase. For reloading, Starbucks Card can be reloaded in denominations of Vietnamese Dong (VND) for any amount between VND50,000 and VND5,000,000, provided that VND5,000,000 is the maximum amount a card can hold at any time. You are required to activate/reload the Starbucks Card by cash at a Starbucks store or using online payment function (via credit card/debit card/domestical ATM card) which is provided by our payment service providers, but you can’t use the Starbucks Card to activate/reload another Starbucks Card.`,
  'termAndCondition.content11': `A non-registered Starbucks Card has a duration of three (03) years as from the last financial transaction by using such non-registered Starbucks Card. Meanwhile, a registered Starbucks Card shall expire on the end date of a three (03) year-period as from the last financial transaction by using such registered Starbucks Card or on the termination date of the Starbucks Rewards™ Program as notified to the competent authority and announced at the Starbucks stores in Vietnam, subject to the sooner date. Within the scope of these Terms, the “financial transaction” shall be one of the following actions:`,
  'termAndCondition.content12': `• Activation`,
  'termAndCondition.content13': `• Reload`,
  'termAndCondition.content14': `• Redemption Rewards for a registered Starbucks Card`,
  'termAndCondition.content15': `• Purchasing transaction`,
  'termAndCondition.content16': `• Fund Transfer (as defined herein below) between the registered Starbucks Cards within the same account.`,
  'termAndCondition.content17': `Upon the expiration of any Starbucks Card, the Starbucks Card balance shall become revenue of Starbucks without any Starbucks’ responsibility for the Starbucks Card holder.`,
  'termAndCondition.content18': `Joining Starbucks Rewards™ Program`,
  'termAndCondition.content19': `You can enroll in Starbucks Rewards™ Program and begin accumulating Stars by visiting `,
  'termAndCondition.content20': `  or installing applicable Mobile Application and following prompts to create a Starbucks Rewards™ account and register at least one (1) activated Starbucks Card to such account. Upon signing up an account, you will receive a verification email to your email address that is used for signing up and activating your account (Please contact us if you do not receive the verification email within 24 hours). The registration process may require that you provide the following information: password, email address (required in order to receive all eligible Rewards), physical address, telephone number, the activated Starbucks Card number, and card security code (CSC), birthday, name and your preference on receiving the program’s news and promotions, preferences.`,
  'termAndCondition.content21': `You can have one (1) Starbucks Rewards™ account only. If you have more than one (1) Starbucks Rewards™ account, Starbucks may determine in its sole discretion that you have violated these Terms of Use. Starbucks may, in its sole discretion, suspend, cancel or combine accounts that appear to be duplicative. In the event that your participation into Starbucks Rewards™ Program is terminated, then all coupons and accumulated Stars in your account are void.`,
  'termAndCondition.content22': `There must be at least 01 sale transaction made on the account every year (twelve (12) months) after a successful registration to be eligible to enjoy the benefits of Starbucks Rewards™.`,
  'termAndCondition.content23': `The Starbucks Card can be registered to your account at any given time. You can register / add maximum twenty (20) activated Starbucks Cards in such account. The Balance of a registered Starbucks Card (a transferred Starbucks Card) can be fully transferred to another registered Starbucks Card balance within one account (Fund Transfer), but the partial transfer is not permitted. The transferred Starbucks Card becomes empty yet remains active for further reloading and use. The invalid Starbucks Card is defined as no Stars earned. The transfer of Starbucks Card balance can be only performed personally by you via our website or on Mobile Application (Fund Transfer).`,
  'termAndCondition.content24': `Earning Stars`,
  'termAndCondition.content25': `The benefits that are available to you through Starbucks Rewards™ Program are based on the number of “Stars” that you earn.`,
  'termAndCondition.content26': `You can earn Stars by:`,
  'termAndCondition.content27': `• Making purchases and payments with Starbucks Cards: You use an your Starbucks Card which has been activated and registered into your Starbucks Rewards™ account to pay for your purchases at any Starbucks store, or for purchases made through the “Order & Pick Up” (“O&PU”) or “Starbucks Delivers” (“SBD”) features on the Mobile Application (as described below). Please note that your spending by using Starbucks Card at Starbucks stores will only be calculated and Stars accumulated into your Starbucks Rewards™ account in case where you present your relevant registered Starbucks Card or barcode on Mobile Application for scanning by the Starbucks’s staff at the time of payment. Your spending via the O&PU and SBD features on Mobile Application will be automatically calculated and Stars accumulated into your Starbucks Rewards™ account immediately upon your completion of your payment.`,
  'termAndCondition.content28': `• Making purchases and payments via Grab mobile application (“Grab App”): You purchase and pay for items at Starbucks stores through Grab App and have successfully linked your Starbucks Rewards™ account with your Grab account (“Grab Account”) on this Grab App (as described below) for the purpose of earning Stars under the Starbucks Rewards™ Program. Your spending for purchasing Starbucks items via the Grab App will automatically calculated and Stars accumulated into your Starbucks Rewards™ account immediately after your pament is completed.`,
  'termAndCondition.content29': `Rules for Stars calculation based on your spending:`,
  'termAndCondition.content30': `• Payment with Starbucks Card: The number of Stars you earn is based on the value of your purchase at the rate of one (01) Star for every forty thousand Vietnamese Dong (VND40,000) you paid for that purchase.`,
  'termAndCondition.content31': `• Payment through Grab App: The number of Stars you earn is based on the value of your purchase at the rate of one (1) Star for every eighty thousand Vietnamese Dong (VND80,000) you paid for one order (after deducting any incentives, discounts, and excluding delivery fees, fixed fees or other fees as regulated by Grab).`,
  'termAndCondition.content32': `Please note that, at Starbucks’ sole discretion, Stars may be earned and awarded in fractional amounts depending on the value of each your purchase paid fully and in accordance with these Terms of Use.`,
  'termAndCondition.content33': `Generally speaking, Stars for your qualifying purchases are automatically added to your account at the earliest after the transaction is completed, or within approximately forty-eight (48) hours. From time to time, we, or others acting with our permission, may offer you special promotions that offer you “Promotional Stars.” Promotional Stars, such as “bonus” Stars, will be awarded as described in the applicable promotional offer and will be subject to any additional terms set forth in the promotional offer.`,
  'termAndCondition.content34': `Starbucks Rewards™ Program Benefit Levels`,
  'termAndCondition.content35': `Currently, there are two (2) levels of benefits within Starbucks Rewards™ Program that are determined by a number of Stars that you accumulate in your account each consecutive twelve (12)-month period. By opening an account and registering an activated Starbucks Card, you will be automatically enrolled in Starbucks Rewards™ Program at the Green Level. If you earn one hundred (100) new Stars within the twelve (12)-month period following your enrollment date, you will qualify for the Gold Level.`,
  'termAndCondition.content36': `Once you reach a specific level within Starbucks Rewards™ Program, you will receive the benefits that are applicable to that level at that time.`,
  'termAndCondition.content37': `To maintain all benefits of the Green Level, you only need to register at least one (1) of your activated Starbucks Card to your account.`,
  'termAndCondition.content38': `To maintain all benefits of the Gold Level, you must earn at least one hundred (100) new Stars during each consecutive twelve (12)-month period following the date that you reached the Gold Level. At the anniversary date of the Gold level, your star count will be reset. For your information, the reset at Gold Level means the Stars balance after dividend by 25, and not more than 25 Stars. If you do not earn one hundred (100) new Stars in each twelve (12)-month period, you will be placed at the Green Level of Starbucks Rewards™ Program and your Star balance will automatically reset to zero (0).`,
  'termAndCondition.content39': `Please note that Stars accumulated for each level of the Starbucks Rewards™ Program will only be valid for twelve (12)-month from the date when you reach the particular level. However, any Rewards you have accumulated but which have not been used and have not expired will remain available in your account and you can redeem such Rewards in accordance with the prescribed conditions herein.`,
  'termAndCondition.content40': `You can view or track your Star balance and available Rewards on `,
  'termAndCondition.content41': ` or the Mobile Application. Starbucks reserves the right to change a number of benefit levels and to change the benefits and Rewards associated with each level in its sole discretion at any time.`,
  'termAndCondition.content42': `Green Level`,
  'termAndCondition.content43': `By registering your activated Starbucks Card, you are automatically entered into the “Green Level” of Starbucks Rewards™ Program, or failure to earn one hundred (100) Stars in a twelve (12)-month period for upgrading the Gold Level, you will remain at the “Green Level” and your Star balance will be reset to zero (0). Rewards may include:`,
  'termAndCondition.content44': `1. Buy-One-Get-One Reward (“BOGO Reward”). You will receive one (1) Buy-One-Get-One on tall or above-sized handcrafted beverages upon earning your first five (5) Stars, as described below:`,
  'termAndCondition.content45': `• Your BOGO Reward coupon will be automatically added to your account within approximately forty-eight (48) hours upon earning your first five (5) Stars in one Starbucks Rewards™ account. The offer for the BOGO Reward coupon expires after one (1) month from the date that such coupon is loaded onto your account. You must present your registered physical Starbucks Card or relevant barcode on Mobile Application to the barista at a Starbucks store or proceed on the Mobile Application in order to redeem your coupon and receive your BOGO Reward. The BOGO Reward may be redeemed at Starbucks stores (O&PU feature on Mobile Application can be used for your placing order) or via SBD feature on Mobile Application for home delivery, and is not available to ready-to-drink bottled beverages.`,
  'termAndCondition.content46': `2. Birthday Reward. You will receive one (1) complimentary slice of cake in the month of your birthday as described below:`,
  'termAndCondition.content47': `• The complimentary Birthday Reward coupon will be automatically added to your account on the first day of your birthday month. The offer for the complimentary Birthday Reward expires the day after your birthday month. You must present your registered Starbucks Card or relevant barcode on the Mobile Application to the barista at a Starbucks store in order to redeem your coupon and receive your complimentary Birthday Reward. You will receive only one (1) Birthday Reward regardless of the number of registered Starbucks Cards you may have. The complimentary Birthday Reward may be redeemed at Starbucks stores (O&PU feature on Mobile Application can be used for your placing order) or via SBD feature on the Mobile Application for home delivery.`,
  'termAndCondition.content48': `Gold Level`,
  'termAndCondition.content49': `Upon earning one hundred (100) Stars in a twelve (12)-month period, you will be placed at the “Gold Level” and your Star balance will be reset. For your information, the reset at Gold Level means the Stars balance after dividend by 25, and not more than 25 Stars. During each twelve (12)-month period when you reach and/or maintain the Gold Level, the benefits of the Gold Level may include:`,
  'termAndCondition.content50': `1. Green Level Rewards. You will still be able to redeem any Green Level Rewards which have not been redeemed and have not expired before you reached the Gold Level, however you must redeem such unused Rewards within their prescribed validity period.`,
  'termAndCondition.content51': `2. Birthday Reward. You will receive one (1) complimentary slice of cake in the month of your birthday as described below:`,
  'termAndCondition.content52': `• The complimentary Birthday Reward coupon will be automatically added to your account on the first day of your birthday month. The offer for the complimentary Birthday Reward coupon expires the day after your birthday month. You must present your relevant registered Starbucks Card or barcode on the Mobile Application to the barista at a Starbucks store in order to redeem your coupon and receive your complimentary Birthday Reward. You will receive only one (1) Birthday Reward regardless of the number of registered Starbucks Cards you may have. The complimentary Birthday Reward may be redeemed at Starbucks stores (O&PU feature on the Mobile Application can be used for your placing order) or via SBD feature on Mobile Application for home delivery.`,
  'termAndCondition.content53': `3. Buy-One-Get-One Reward (“BOGO Reward”). You will receive one (1) BOGO Reward on any tall or above-sized handcrafted beverage as described below:`,
  'termAndCondition.content54': `Your BOGO Reward coupon will be automatically loaded onto your account within approximately forty-eight (48) hours upon reaching the Gold Level. The offer for the BOGO Reward coupon expires after one (1) month from the date that such Reward is loaded onto your account. You must present your relevant registered Starbucks Card or barcode on Mobile Application to the barista at a Starbucks store or proceed on the Mobile Application in order to redeem your Coupon and receive your BOGO Reward. The BOGO Reward may be redeemed at Starbucks stores (O&PU feature on the Mobile Application can be used for your placing order) or via SBD feature on Mobile Application for home delivery, and is not available to ready-to-drink bottled beverages.`,
  'termAndCondition.content55': `4. Free Drink Reward. You will receive one (1) free tall size handcrafted drink of your choice for every twenty-five (25) additional Stars that you earn above the one hundred (100) Stars needed to reach the Gold Level, as described below:`,
  'termAndCondition.content56': `• Your Free Drink Reward coupon will be automatically loaded onto your account within approximately forty-eight (48) hours upon you earning every twenty-five (25) additional Stars. The Free Drink Reward coupon expires after one (1) month from the date that the Free Drink Reward is loaded onto your account. You must present your relevant registered Starbucks Card or barcode on Mobile Application to the barista at a Starbucks store or proceed on the Mobile Application in order to redeem your Coupon and receive your Free Drink Reward. You will receive only one (1) Free Drink Reward for every twenty-five (25) additional Stars that you accumulate. The Free Drink Reward may be redeemed at Starbucks stores (O&PU feature on the Mobile Application can be used for your placing order) or via SBD feature on Mobile Application for home delivery, and is not available to ready-to-drink bottled beverages.`,
  'termAndCondition.content57': `5. Special offers and/or invitations. You may, at the sole discretion of Starbucks, receive additional special offer(s) and/or invitation(s) to participate in event(s) held by or for Starbucks by email or physical mail. You must opt-in to receive promotional and marketing offers from Starbucks and may be required to provide a valid email, telephone and physical address in order to receive your special offers and invitations. Special offers and invitations may be customized based on purchase behaviors and preferences. Special offers may include periodic discounts on foods, drinks and merchandise. Special offers may have certain restrictions including expiration dates and short time-limited redemption periods. Special offers are personal to you and cannot be shared, copied or transferred. You may be required to present your relevant registered Starbucks Card or barcode on Mobile Application in order to redeem special offers. Read each offer carefully for specific details, expiration dates, limitations and restrictions when such offer is loaded onto your account.`,
  'termAndCondition.content58': `You are responsible for updating your Starbucks Rewards™ account and ensuring all information you provide in your account is accurate and up to date at all times, including your email and mailing addresses at `,
  'termAndCondition.content59': `. If any information you provide is not accurate or up to date, you may not receive special offer(s) and/or invitation(s). You can view and track your available offer(s) and/or invitation(s) on `,
  'termAndCondition.content60': ` or on the Mobile Application.`,
  'termAndCondition.content61': `Order & Pick Up on Mobile Application`,
  'termAndCondition.content62': `This Order & Pick Up (“O&PU”) feature on Mobile Application allows you to place an order and pay ahead before reaching the selected Starbucks store. By using this feature, you can send and ask a barista for your freshly made order at the pickup area at estimated pick-up time without waiting in line nor additional service fee for this feature. This feature is only integrated into the Mobile Application and applied for member(s) of Starbucks Rewards™ Program. You are requested to turn on your location services and ensure the most recent update for the Mobile Application for discovering this new feature.`,
  'termAndCondition.content63': `The O&PU is currently available via the Mobile Application for your use by the following simple steps:`,
  'termAndCondition.content64': `1. Open the latest version of the Mobile Application and log into your account`,
  'termAndCondition.content65': `2. Click on the "Order" option from the menu: Your location services on your devices are requested to turn on for starting to use this feature`,
  'termAndCondition.content66': `3. Click ”Order &Pickup”.`,
  'termAndCondition.content67': `4. Select the store for pick up:  Menu items will also be updated based on availability after the Starbucks store is selected.`,
  'termAndCondition.content68': `5. Select food and beverage items to order: Just as in our stores, food and beverages are customizable, including numerous options to modify size, number of espresso shots, dairy selections and more. You should review information of each items and select “Add to Order” for confirmation.`,
  'termAndCondition.content69': `6. Review the Order and estimated time for preparation of ordered items by clicking “Next”: You can use coupon already added to your account for payment. Details information of your orders, estimated pick-up time, pick-up store and total amount for your payment will be displayed for your review and confirmation`,
  'termAndCondition.content70': `7. Proceed the payment: Payment is made from one of your Starbucks Cards already registered in your account. You can select your Starbucks Card you wish to use for the payment. `,
  'termAndCondition.content71': `8. Finish your order by clicking “Submit Order”: A pop-up notification with eReceipt for your order and a count down of estimated pick-up time will be shown. You can also check such information and at Transaction history.`,
  'termAndCondition.content72': `9. Proceed to the selected Starbucks store to pick up your food and beverages: Orders are immediately sent to the selected Starbucks store where baristas will begin preparing the items. When baristas have finished your order, you will receive a push notification provided via the Mobile Application for picking up within the estimated pick-up time and enjoy your food / drink.`,
  'termAndCondition.content73': `Initially, the Mobile Application will default to your latest order and the closest available Starbucks store within fifty (50) kilometers to your current location, as well as display the current menu availability and estimated pick-up time for your order from that store. You can change stores by tapping the map or using the "Pickup at" button in the app. Once your order is submitted, you cannot change the pickup store. You can only place an order once the selected Starbucks store is open and up to 30 minutes before such Starbucks store is closed. `,
  'termAndCondition.content74': `Almost all of your favorite Starbucks beverages and food are available through the O&PU except for alcoholic beverage, ready to drink, merchandise and whole beans. The menu for your selection will list all available offerings at the same price at the selected Starbucks store. With a few taps on your device, you can customize your favorite beverages and choose to have food items served warm, just as you can with in-store orders.`,
  'termAndCondition.content75': `When you place your order, you will be given an estimated pick-up time without any option for you to change or specify such time. Times will vary depending on a variety of factors including the Starbucks store availability, a number of items ordered and time of day. You can go to the selected Starbucks store within the approximate pick-up time.`,
  'termAndCondition.content76': `The payment for your order via the O&PU must be completed via a registered Starbucks Card through the Mobile Application. Your prepaid amount on such registered Starbucks Card will be deducted for such order transaction by our system. Starbucks also has its own security standards for this deduction transactions to ensure the security and safety information. In the event of an unexpected transaction occurred, please contact our Starbucks Rewards™ Customer Care Center so that we can assist promptly.`,
  'termAndCondition.content77': `After placing an order through the O&PU, it is immediately sent to the selected Starbucks store and our baristas will begin preparing the order. You cannot change the selected Starbucks store, modify or cancel the order, return the order items, request for refund in any circumstances, or place any other order at a time.`,
  'termAndCondition.content78': `Please ensure notifications are allowed under settings in the Mobile Application and your device in order to receive push notification when your order is ready for pick up at selected Starbucks store within the estimated pick-up time. You acknowledge that Starbucks cannot maintain the quality of the ordered items as you wish in the event that you pick up later than the estimated pick-up time despite of receiving the push notification and agree not to hold Starbucks harmless from any liability in this event. However, to the possible extent, we always try to bring the best quality products to you.`,
  'termAndCondition.content79': `You can also earn Stars for the purchases made through the O&PU`,
  'termAndCondition.content80': `You can contact our Starbucks Rewards™ Customer Care Center according to information at the below if you have any problem during using the O&PU.`,
  'termAndCondition.content81': `“Starbucks Delivers” feature on Mobile Application`,
  'termAndCondition.content82': `“Starbucks Delivers” (“SBD”) feature on Mobile Application allows you to place an order and pay ahead for your purchase and the respective delivery fee, and to receive the ordered items by youself or a person designated by you with contact details (collectively, “Recipient”) for our delivery at a address designated (or shared with us via your device) by you, without having to pick up your ordered items at the Starbucks store’s delivery area. With this SBD feature, you can order eight (8) food/bevarage items in maximun per order. Your order will be sent to the barista at the nearest Starbucks store (within a radius of three (3) kilometers from the requested delivery location), and we will designate a delivery service provider (a “Delivery Person”) to deliver the paid order to the Recipient.`,
  'termAndCondition.content83': `This SBD feature is integrated into Mobile Application only and applicable to Starbucks Rewards™ Program member(s). You are required to enable location services on your device and make sure the Mobile Application is up to date to utilize this new feature.`,
  'termAndCondition.content83.2': `This SBD feature is available via Mobile Application for your use by the following simple steps:`,
  'termAndCondition.content84': `1. Open the latest version of the Mobile Application and log in to your account.`,
  'termAndCondition.content85': `2. Click on the “Order” option from the menu.`,
  'termAndCondition.content86': `3. Click on “Starbucks Delivers”.`,
  'termAndCondition.content87': `4. Share your location or select delivery address and Receipt information: The Mobile Application will automatically search and select the nearest store within a radius of three (3) km of the delivery address you have shared or selected.`,
  'termAndCondition.content88': `5. Select food and beverage items to order: Just as in our stores, food and beverages are customizable, including multiple options to modify size, number of espresso shots, dairy selections and more. Menu of foods and beverages will be updated based on availability at the Starbucks store selected in step 4 above. You should review information of each items and select “add to cart” for confirmation`,
  'termAndCondition.content89': `6. Review Order and confirm by clicking "Next": You can use the coupon that has been added into your account for payment. Details of your order, your delivery address, phone number and your total payment amount (including delivery fee) will be displayed for your review and confirmation. Please note that in the event you use this SBD feature only for the receipt of Birthday Reward or Free Drink Reward in one (1) order, you are still required to confirm the displayed delivery fee before proceeding the payment in step (7) right below.`,
  'termAndCondition.content90': `7. Proceed the payment: Payment is made with a Starbucks Card registered to your account. You can choose the Starbucks Card you want to use for payment.`,
  'termAndCondition.content91': `8. Finish your order by clicking “Submit Order”: A pop-up notification with eReceipt for your order will be displayed on the homepage with updates of your order status and estimated delivery time range to the Recipient. You can also check this information at Transaction History.`,
  'termAndCondition.content92': `9. Receive food and beverage items at your selected address: The order is immediately sent to the selected Starbucks store for the barista to prepare the foods and beverages. The Delivery Person will come to the store to pick up the ordered items and deliver the same to the Recipient. You can track the Delivery Person's movement through Mobile Application. Notwithstanding being notified of the estimated delivery time range to the Recipient as displayed on Mobile Application, you agree that, in practice, such delivery time is for reference only and may be reasonably changed depending on a number of factors, including but not limited to, the availability of the Starbucks store in question, number of ordered foods and beverages, ordering moment, traffic conditions of the delivery route, weather, restrictions on access to the delivery area.`,
  'termAndCondition.content93': `Initially, the Mobile Application will default to the closest available Starbucks store within a radius of three (3) kilometers as from your selected delivery location, and display the available menu of such store for your selection and estimated delivery time. You cannot change the store default selected by the Mobile Application. You can only place an order once the selected Starbucks store is open and up to thirty (30) minutes before such store is closed.`,
  'termAndCondition.content94': `Almost all of your favorite Starbucks beverages and foods are available through the SBD feature except for alcoholic beverages, ready-to-drink, merchandise and whole coffee beans. The menu for your selection will list all available foods and beverages at the price same to ones at the selected Starbucks store. With a few taps on your device, you can customize your favorite beverages and choose to have ordered items warmed, just as you could ask with in-store orders. Please note that the food that has been heated at your request may  be cooled during transportation, depending on the distance, traffic conditions, weather, and other factors, and therefore you acknowledge and agree that we do not take any responsibility for such food being cold.”`,
  'termAndCondition.content95': `Delivery fee will be applied to each order placed through the SBD feature. Please note that the delivery fee may vary depending on the delivery time and the delivery distance to the Recipient, and will be clearly displayed (along with other order details) for your review and confirmation, and proceeding with the payment. Payment for your order via the SBD feature must be completed through Mobile Application with a registered Starbucks Card. Your prepaid amount on such registered Starbucks Card will be deducted by our system for your processed purchase transaction. Starbucks also has its own security standards for these deduction transactions to ensure that the information is secure and safe. In the event of an unexpected transaction, please contact our Starbucks Rewards™ Customer Care Center so that we can assist you in a timely manner.`,
  'termAndCondition.content96': `Once a Delivery Person has confirmed his/her acceptance of the delivery of items under your order to the Recipient and you have completed the step of payment via the SBD feature, you will receive a notification (eNotification) confirming your order, and the order will be immediately sent to the Starbucks store default selected by the Mobile Application for baristas to prepare the items under the order. You cannot change the default selected Starbucks store, modify or cancel your order, return your ordered beverages and foods, or request a refund for an order which has been confirmed and paid for, except in the event that the ordered items have not been delivered or have been damaged, broken, spilled or deformed, so that they are no longer usable due to either our or the Delivery Person’s fault. Furthermore, at a moment, you can place only one (1) SBD order, i.e. you can place one (1) subsequent SBD order after the confirmation of successful delivery of your previous SBD order. However, you can place at the same time an unlimited number of O&PU orders in addition to one (01) SBD order.`,
  'termAndCondition.content97': `Upon your receipt of ordered items from the Delivery Person, you shall check the items delivered under the respective order. In the event that the ordered items delivered to the Recipient by the Delivery Person in a damaged, broken, spilled, or dented condition, so that they are unusable, and accordingly, you want to refuse to accept them, or the quantity is incorrect , please take a photo at the time of delivery as evidence of the items delivered by the Delivery Person, and contact our Starbucks Rewards™ Customer Care Center within twenty-four (24) working hours upon your receipt from the Delivery Person ("Claim Period") to submit your claim, evidence and request for our consideration and processing in accordance with these Terms of Use, and we reserve the right to exchange or refund at our sole discretion for the items you refuse to receive or lacked item based on our review and acceptance of the evidence provided by you. Please note that the working hours of the Starbucks Rewards™ Customer Care Center are Monday - Friday (8:30 am - 5:00 pm) and Saturday (8:30 am - 12 pm); and for your order that the Delivery Person has confirmed the successful delivery and the Claiming Period has expired, you will no longer be able to claim for any issues as mentioned above in this paragraph.`,
  'termAndCondition.content98': `In the event that the Recipient can not receive the ordered items or the Delivery Person cannot deliver the ordered items because of your fault, including but not limited to, your providing the wrong address, sharing the wrong location, providing the wrong Recipient’s  information and/or the Delivery Person is unable to contact the Recipient after three (03) calls, with five (5)-minute intervals between each call  through the phone number provided by you, or the like, the order status is still recorded on the Mobile Application as completed/ sucessfully delivered, and in this case we do not take any responsibility for you. However, in such case, you may contact our Starbucks Rewards™ Customer Care Center so that we can support and process your request for such order at our sole discretion as we deem reasonable and in accordance with these Terms of Use.`,
  'termAndCondition.content99': `Depending on the information and evidence provided by you in respect of the status of delivered items or your failure to receive the paid order, we will consider your request on a case-by-case basis and may refund you  at our sole discretion, as we deem reasonable and in accordance with these Terms of Use. For clarity, it is your responsibility to ensure that the delivery information you provide to us (Recipient’s address, name and phone number, etc.) is accurate, complete and updated. Once the order has been confirmed, you cannot modify these delivery information. Please carefully check such delivery information before proceeding with your confirmation and payment. `,
  'termAndCondition.content100': `The calculation and accumulation of your Stars shall be based on the total value of your SBD order (including delivery fee).`,
  'termAndCondition.content101': `You can contact our Starbucks Rewards™ Customer Care Center according to the information herein below if you have any problem during your use of the SBD feature.`,
  'termAndCondition.content102': `Linking your Starbucks Rewards™ account with Grab Account`,
  'termAndCondition.content103': `You can make purchases from Starbucks stores via the Grab App and choose to link your Starbucks Rewards™ account with the Grab Account by using the account linking feature available on the Grab App ("Linking Feature") for the purpose of earning Stars under this Starbucks Rewards™ Program based on your spending for each order paid fully.`,
  'termAndCondition.content104': `In order to link your Starbucks Rewards™ account with Grab Account, you need to take the following steps on the Grab App:`,
  'termAndCondition.content105': `1. Open the latest version of the Grab App and log in to Grab Account.`,
  'termAndCondition.content106': `2. Select food and beverage items to order: Go to “Food” on Grab App, then you can search for Starbucks stores in the “Search” bar, and select the Starbucks store from which you want to order items. Just as in our stores, foods and beverages are customizable, including multiple options to modify size, number of espresso cups, milk and other options. Foods and beverages will be updated based on availability at the selected Starbucks store. You can review information for each item and select "Add to basket" for confirmation.`,
  'termAndCondition.content107': `3. Review the order: After selecting the items, click on the “Basket” item to go to the Basket page of the Grab App. On the Basket page, the details of your order, such as delivery address, delivery phone number and total amount of payment (including shipping fee) will be displayed for your review and confirm.`,
  'termAndCondition.content108': `4. Link the account: Also on this “Basket” page, you can choose to link your Starbucks Rewards™ account with Grab Account for the purpose of earning Stars under the Starbucks Rewards™ Program by clicking “Membership”, then clicking on the “Add Account” option.`,
  'termAndCondition.content109': `Here, the Starbucks website will be displayed inside the Grab App (in-app browser). This Starbucks website will display this Terms of Use and the Privacy Policy of Starbucks for your review and acceptance. In case of not accepting this Terms of Use and the Privacy Policy of Starbucks, you will be unable to link your Starbucks Rewards™ account with Grab Account. If you are already a member of the Starbucks Rewards™ Program, please click “Yes”, and then provide the information of your Starbucks Rewards™ account and link the two accounts. If you are not yet a member of the Starbucks Rewards™ Program, please click “No”, and then register your Starbucks Rewards™ account and join the Starbucks Rewards™ Program, accordingly, such your registered Starbucks Rewards™ account will be linked to the Grab Account for the purpose of earning Stars.`,
  'termAndCondition.content110': `Once after you have logged in / registered your Starbucks Rewards™ account, the linking of your Starbucks Rewards™ account and Grab Account has been successful, and you will be automatically redirected to the “Basket” page of the Grab App, so that you can proceed the payment and track your order in accordance with the Grab App.`,
  'termAndCondition.content111': `You only have to go through only one (01) time the steps of the Linking Feature to link your Starbucks Rewards™ account with Grab Account, i.e you do not have to repeat such steps for your following orders after successfully linking your Starbucks Rewards™ account and Grab Account.`,
  'termAndCondition.content112': `5. Notice of the number of Stars accumulated for each order: For each order paid fully by you, the value thereof will automatically caculated and Stars accumulated into your Starbucks Rewards™ account, and you will be redirected to the Order tracking page of the Grab App. On this page, you will be noticed whether or not you have earned Stars from the value of order you have just made payment for. In case of earning Stars, once the order has been completed (meaning you have received the ordered items), you will receive the following notice: “You’ve earned Stars for this order”. To check your Stars balance, click on the icon “(i)” right next to the notice, then click “Go to Starbucks Vietnam app” to be redirected to the Mobile Application.`,
  'termAndCondition.content113': `6. Remove the accounts linking: You can remove the linking between your Starbucks Rewards™ account and Grab Account at any time by selecting “Remove account” in the settings of the Grab App (clicking on the gear icon in the upper right corner of the screen).`,
  'termAndCondition.content114': `Miscellaneous`,
  'termAndCondition.content115': `There is no membership fee associated with Starbucks Rewards™ Program. You can online register your Starbucks Rewards™ account on the Mobile Application to join Starbucks Rewards™ Program with one (1) E-Card with a value of VND0 added to your account once your account registration is successful. However, in order to use the O&PU and/or the SBD feature, you must have an activated Starbucks Card with loaded amount sufficient to pay for your purchase in your account. You must load at least VND100,000 for activating your plastic Starbucks Card at Starbucks stores. Stars accumulated under the program have no cash value.`,
  'termAndCondition.content116': `Starbucks may introduce promotion programs relating to Starbucks Rewards™ Program whereby these Terms of Use may be varied as applicable to such programs.`,
  'termAndCondition.content117': `The Starbucks Rewards™ Program shall be performed in the duration as notified to the competent authority and announced at the Starbucks stores in Vietnam. Accordingly, your Starbucks Rewards™ account and all your registered Starbucks Cards shall expire on termination date of the Starbucks Rewards™ Program, so that all accumulated Stars in your account are void on that termination date without any Starbucks’ responsibility to you. Under this circumstance, you will be notified to receive/redeem the balance of all your registered Starbucks Cards within the specified period which will be notified specifically to you. `,
  'termAndCondition.content118': `Delete your account`,
  'termAndCondition.content119': `You may request to delete your Starbucks Rewards™ account at any time through the account deletion process provided at `,
  'termAndCondition.content120': ` or Mobile Application. Please note that: (a) All account deletion requests are final and cannot be changed once processed; and (b) once your account is deleted, all information and benefits related to your deleted account will be inaccessible and unrecoverable, including your account history, Starbucks Card balances, Stars and any other rewards or benefits.`,
  'termAndCondition.content121': `Unless otherwise required by applicable law, Starbucks will not accept or issue any exchanges or refunds for goods or services purchased at its Starbucks stores. In case of any refunds, Starbucks reserves the right to deduct, at its sole discretion, benefits, Stars earned, including Promotional Stars, if any, in respect of your purchase of the refunded goods or services.`,
  'termAndCondition.content122': `If your Starbucks Card is lost, stolen, or destroyed, you may lose the Starbucks Card balance. However, in case of member’s Starbucks Rewards™ Program, you may protect the Star balance on your Starbucks Rewards™ account and the Balance of such Starbucks Card if lost, stolen or destroyed by notifying it at `,
  'termAndCondition.content123': ` or Mobile Application or calling to our Starbucks Rewards™ Customer Care Center at (028) 7306 7676, and confirming your instruction. In case of having only one registered Starbucks Cards, a request for a replacement Card can be sent to us, accordingly, all Balance of the lost/stolen/destroyed Starbucks Card can be transferred to the alternative Card and you can continue to redeem the unused Rewards within their prescribed validity period by using such alternative Card, otherwise, the Balance of the lost/stolen/destroyed Starbucks Card can be transferred to the others of yours.`,
  'termAndCondition.content124': `Your Stars, Rewards, registered Starbucks Card(s), and your account under Starbucks Rewards™ Program are personal to you and may not be sold, transferred or assigned to, or shared with family, friends or others, or used by you for any commercial purpose. You are required to use your email address for registration of your Starbucks Rewards™ account that is personal to you, and each your email address can be used for register only one (1) Starbucks Rewards™ account.`,
  'termAndCondition.content125': `Without notice to you, Starbucks also reserves the right to “unregister” your Starbucks Rewards™ account for any Starbucks Card which has expired or has been inactivated. In the event that your Starbucks Rewards™ account or Starbucks Card is unregistered or rendered inactive, then all accumulated Stars in your account are void. `,
  'termAndCondition.content126': `Without notice to you, Starbucks reserves the right to suspend any of your registered Starbucks Card and/or terminate your Starbucks Rewards™ account and/or your participation into Starbucks Rewards™ Program if Starbucks determines in its sole discretion that you have violated any term of these Terms of Use and terms incorporated by reference, or one your email address is used for registration of more than one (1) Starbucks Rewards™ account, or that the use of your Starbucks Rewards™ account is unauthorized, deceptive, fraudulent or otherwise unlawful. Starbucks may, in its sole discretion, suspend, cancel or combine accounts that appear to be duplicative. In the event that your participation into Starbucks Rewards™ Program is terminated, then all accumulated Stars in your account are void, and all your registered Starbucks Cards balance shall become revenue of Starbucks without any Starbucks’ responsibility for the Starbucks Card holder.`,
  'termAndCondition.content127': `All claims or disputes arising out of or in relation to these Terms of Use or the Starbucks Rewards™ Program shall be resolved in accordance with applicable laws in Vietnam.`,
  'termAndCondition.content128': `If you have any inquiries related to Starbucks Rewards™ Program, please contact Viet Idea Food & Beverages Company Limited by postal mail at Level 14, Phuong Long 2 Building, 16 Nguyen Truong To, Ward 13, District 4, Ho Chi Minh City or via our Starbucks Rewards™ Customer Care Center at (028)7306 7676. `,
};
