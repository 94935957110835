export default {
  'successLinkPage.allSet': 'Hoàn tất',
  'successLinkPage.sub.title': 'Đưa bạn trở lại ứng dụng Grab để tích lũy thêm Điểm Ngôi Sao',
  'successLinkPage.note': 'đối với đơn hàng qua Grab*',
  'successLinkPage.more.detail1': 'Xem thêm thông tin chi tiết và phần thưởng trên ',
  'successLinkPage.more.detail2': 'ứng dụng Starbucks Việt Nam',
  'successLinkPage.more.detail3': ' hoặc ',
  'successLinkPage.sub.note1':
    'Miễn phí 01 ly nước với mỗi 25 Điểm Ngôi Sao tích lũy (dành riêng cho thành viên hạng Vàng)',
  'successLinkPage.sub.note2': 'Miễn phí 01 phần bánh trong tháng sinh nhật',
  'successLinkPage.sub.note3': 'Ưu đãi dành riêng cho thành viên',
};
