import forgotPassword from './vi/forgotPassword';
import forgotPasswordSuccess from './vi/forgotPasswordSuccess';
import global from './vi/global';
import login from './vi/login';
import signUp from './vi/signUp';
import successLinkPage from './vi/successLinkPage';
import termAndCondition from './vi/termAndCondition';
import welcome from './vi/welcome';

export default {
  login: login,
  termAndCondition: termAndCondition,
  successLinkPage: successLinkPage,
  signUp: signUp,
  welcome: welcome,
  forgotPassword: forgotPassword,
  forgotPasswordSuccess: forgotPasswordSuccess,
  global: global,
};
