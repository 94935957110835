import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LOCAL_STORAGE_NAMES } from 'utils/constant';

import AppEn from './en';
import AppVi from './vi';

const getDefaultLang = () => 'VI';

export const getCurrentLanguage = () =>
  localStorage.getItem(LOCAL_STORAGE_NAMES.CURRENT_LANGUAGE) || getDefaultLang();

i18n.use(initReactI18next).init({
  resources: {
    VI: {
      translation: {
        ...AppVi.login,
        ...AppVi.termAndCondition,
        ...AppVi.successLinkPage,
        ...AppVi.signUp,
        ...AppVi.welcome,
        ...AppVi.forgotPassword,
        ...AppVi.forgotPasswordSuccess,
        ...AppVi.global,
      },
    },
    EN: {
      translation: {
        ...AppEn.login,
        ...AppEn.termAndCondition,
        ...AppEn.successLinkPage,
        ...AppEn.signUp,
        ...AppEn.welcome,
        ...AppEn.forgotPassword,
        ...AppEn.forgotPasswordSuccess,
        ...AppEn.global,
      },
    },
  },
  lng: getCurrentLanguage(),
  fallbackLng: getCurrentLanguage(),

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
