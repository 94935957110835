export default {
  'termAndCondition.title': 'Starbucks Việt Nam',
  'termAndCondition.title.confirm': 'Bạn có phải là thành viên Starbucks Rewards™ không?',
  'termAndCondition.yes': 'Có • Đăng nhập',
  'termAndCondition.no': 'Không • Tham gia Starbucks Rewards™',
  'termAndCondition.title.term2': 'Tôi đồng ý với ',
  'termAndCondition.title.term3': 'Chính sách bảo mật',
  'termAndCondition.title.term4': 'và',
  'termAndCondition.title.term5': 'Điều khoản sử dụng',
  'termAndCondition.title.term6': ' của Starbucks Việt Nam.',
  'termAndCondition.title.privacy': 'Chính sách bảo mật',
  'termAndCondition.title.term': 'Điều khoản sử dụng',
  'termAndCondition.content1':
    'VUI LÒNG ĐỌC KỸ NHỮNG ĐIỀU KHOẢN SỬ DỤNG NÀY. BẰNG CÁCH TRUY CẬP HOẶC THAM GIA VÀO CHƯƠNG TRÌNH STARBUCKS REWARDS™ HOẶC SỬ DỤNG THẺ STARBUCKS, BẠN ĐỒNG Ý BỊ RÀNG BUỘC BỞI NHỮNG ĐIỀU KHOẢN SỬ DỤNG NÀY VÀ TẤT CẢ CÁC ĐIỀU KHOẢN ĐƯỢC KẾT HỢP BẰNG VIỆC THAM CHIẾU. NẾU BẠN KHÔNG ĐỒNG Ý VỚI NHỮNG ĐIỀU KHOẢN SỬ DỤNG NÀY, VUI LÒNG KHÔNG THAM GIA VÀO CHƯƠNG TRÌNH STARBUCKS REWARDS™ VÀ KHÔNG SỬ DỤNG THẺ STARBUCKS.',
  'termAndCondition.content2':
    'Các Điều Khoản Sử Dụng này được áp dụng đối với việc bạn truy cập, và sử dụng một phần hoặc toàn bộ website hay ứng dụng di động, và tham gia vào, Chương trình Starbucks Rewards™ do Công ty TNHH Thực phẩm & Nước giải khát Ý Tưởng Việt (“Starbucks”) - đơn vị được cấp phép tại Việt Nam của Starbucks Corporation - thực hiện. Các Điều Khoản Sử Dụng này không làm thay đổi theo bất cứ cách nào các điều khoản hoặc điều kiện của bất cứ thỏa thuận nào khác mà bạn có thể giao kết với Starbucks về các sản phẩm và dịch vụ khác. Starbucks có quyền thay đổi, chỉnh sửa và/hoặc loại bỏ Chương trình Starbucks Rewards™ và/hoặc tất cả hoặc bất cứ phần nào của các Điều Khoản Sử Dụng này hoặc bất cứ chính sách, Câu Hỏi Thường Gặp, hoặc hướng dẫn nào liên quan đến Chương trình Starbucks Rewards™ tại bất cứ thời điểm nào và theo quyền quyết định chỉ riêng của Starbucks. Bất cứ thay đổi hoặc sửa đổi nào sẽ có hiệu lực ngay khi đăng tải và thông báo về những nội dung chỉnh sửa tại ',
  'termAndCondition.content3': `, hoặc ứng dụng di động “Starbucks
    Vietnam” (“Ứng Dụng Di Động”). Sự tham gia của bạn vào Chương trình Starbucks Rewards™ hoặc
    việc sử dụng Thẻ Starbucks xác nhận sự chấp nhận của bạn về các Điều Khoản Sử Dụng này và
    bất cứ thay đổi hoặc sửa đổi nào như thế; do vậy, bạn phải thường xuyên xem các Điều Khoản Sử
    Dụng này và các chính sách áp dụng và Câu Hỏi Thường Gặp để hiểu các điều khoản và điều kiện
    áp dụng cho Chương trình Starbucks Rewards™ và Thẻ Starbucks. Nếu bạn không đồng ý các
    Điều Khoản Sử Dụng này, bạn phải chấm dứt việc tham gia vào Chương trình Starbucks
    Rewards™ và chấm dứt việc sử dụng Thẻ Starbucks.`,
  'termAndCondition.content4': `Chương trình Starbucks Rewards™ là dành cho việc sử dụng cá nhân. Việc sử dụng cho mục đích
    thương mại là bị cấm. Chương trình này không nhằm tới, cũng không được dự liệu cho việc sử
    dụng bởi bất cứ người nào dưới 18 tuổi.`,
  'termAndCondition.content5': `Xin vui lòng xem kỹ Chính Sách Bảo Mật Thông Tin có tại `,
  'termAndCondition.content6': ` để hiểu cách Starbucks thu
  thập, sử dụng, tiết lộ các thông tin về khách hàng và cách để cập nhật hoặc thay đổi thông tin cá
  nhân của bạn cũng như cách chúng tôi liên lạc với bạn.`,
  'termAndCondition.content7': `Chương trình Starbucks Rewards™ là một cách thức thể hiện sự nỗ lực của Starbucks để tri ân và
  cảm ơn khách hàng trung thành ủng hộ hoạt động kinh doanh và mua các sản phẩm Starbucks®
  của chúng tôi. Các thành viên của Chương trình Starbucks Rewards™ có thể nhận được và tích lũy
  “Điểm Ngôi Sao” mà có thể được sử dụng để hưởng các lợi ích của Chương trình Starbucks
  Rewards™, còn được biết đến như là “Ưu Đãi”, tại các cửa hàng Starbucks® tại Việt Nam.`,
  'termAndCondition.content8': `Thẻ Starbucks`,
  'termAndCondition.content9': `Thẻ Starbucks là một thẻ nhựa vật lý mang thương hiệu Starbucks hoặc một thẻ ảo mang thương
  hiệu Starbucks (hoặc “Thẻ Điện Tử”) là loại thẻ quà tặng có thể tái sử dụng nhiều lần khi khách
  hàng nạp tiền vào thẻ và chỉ sử dụng để mua hàng tại các cửa hàng Starbucks. Thẻ Starbucks sẽ
  được nhận dạng bằng số thẻ, mã bảo mật (CSC) và mã vạch của nó. Thẻ Starbucks có giá trị sử
  dụng tại Việt Nam (tại tất cả cửa hàng Starbucks tại Việt Nam), và phải được kích hoạt với giá trị tối
  thiểu là 100.000 VNĐ ở lần sử dụng đầu tiên tại bất kỳ thời điểm nào. Nếu bạn muốn mua Thẻ
  Starbucks làm thẻ quà tặng, thẻ chỉ cần đã kích hoạt. Khi bạn kích hoạt Thẻ Starbucks, bạn có thể
  sử dụng thẻ này để mua hàng như việc bạn sử dụng tiền mặt, tuy nhiên số dư trong Thẻ Starbucks
  không được hoàn lại cũng như không thể chuyển đổi thành tiền mặt hoặc số dư trong thẻ tín dụng.
  Thông thường, để kiểm tra số dư trong Thẻ Starbucks của bạn, hãy đề nghị nhân viên pha chế tại
  bất kỳ cửa hàng Starbucks nào kiểm tra giúp bạn. Đối với các Thẻ Starbucks đã được đăng ký tài
  khoản, bạn có thể kiểm tra số dư tại `,
  'termAndCondition.content10': ` hoặc thông qua Ứng Dụng Di Động
  nếu bạn đã đăng ký tham gia chương trình Starbucks Reward™.  Khi số dư còn lại không đủ để
  mua hàng, bạn có thể nạp thêm tiền vào Thẻ Starbucks để tiếp tục thực hiện việc mua hàng. Đối với
  việc nạp tiền, bạn có thể sử dụng Việt Nam Đồng (VNĐ) nạp vào Thẻ Starbucks với giá trị nạp từ
  50.000 VNĐ đến 5.000.000 VNĐ, trong đó 5.000.000 VNĐ là số tiền tối đa có trong một thẻ tại mọi
  thời điểm. Bạn phải kích hoạt/nạp tiền vào Thẻ Starbucks bằng tiền mặt tại cửa hàng Starbucks
  hoặc sử dụng chức năng thanh toán trực tuyến (thông qua thẻ tín dụng/thẻ ghi nợ/thẻ ATM nội địa)
  do nhà cung cấp dịch vụ thanh toán của chúng tôi cung cấp, nhưng bạn không thể sử dụng Thẻ
  Starbucks để kích hoạt/nạp tiền cho một Thẻ Starbucks khác.`,
  'termAndCondition.content11': `Thẻ Starbucks chưa đăng ký có thời hạn sử dụng trong vòng ba (03) năm kể từ thời điểm giao dịch tài chính cuối cùng được thực hiện bằng cách sử dụng Thẻ Starbucks đó. Trong khi đó, Thẻ Starbucks đã đăng ký sẽ hết hạn vào ngày cuối cùng của thời hạn ba (03) năm kể từ thời điểm giao dịch tài chính cuối cùng được thực hiện bằng cách sử dụng Thẻ Starbucks đó hoặc vào ngày chấm dứt Chương trình Starbucks Rewards™ như đã thông báo cho cơ quan có thẩm quyền và công bố tại các cửa hàng Starbucks ở Việt Nam, tùy thuộc vào thời điểm nào sớm hơn. Trong phạm vi các Điều Khoản Sử Dụng này, "Giao dịch tài chính" được hiểu là một trong các hành động sau đây:`,
  'termAndCondition.content12': `• Kích hoạt thẻ`,
  'termAndCondition.content13': `• Nạp tiền vào thẻ`,
  'termAndCondition.content14': `• Sử dụng Ưu Đãi đối với Thẻ Starbucks đã đăng ký`,
  'termAndCondition.content15': `• Giao dịch mua hàng`,
  'termAndCondition.content16': `• Chuyển tiền (như được định nghĩa dưới đây) giữa các Thẻ Starbucks đã đăng ký trong cùng một tài khoản.`,
  'termAndCondition.content17': `Khi Thẻ Starbucks hết hạn, số dư trong Thẻ Starbucks sẽ trở thành doanh thu của Starbucks mà Starbucks không phải chịu bất kỳ trách nhiệm tài chính nào đối với người sở hữu Thẻ Starbucks.`,
  'termAndCondition.content18': `Tham gia vào Chương trình Starbucks Rewards™`,
  'termAndCondition.content19': `Bạn có thể tham gia vào Chương trình Starbucks Rewards™ và bắt đầu tích lũy Điểm Ngôi Sao bằng cách truy cập vào `,
  'termAndCondition.content20': ` hoặc cài đặt Ứng Dụng Di Động và làm theo hướng dẫn để tạo một tài khoản Starbucks Rewards™ và đăng ký vào tài khoản đó tối thiểu một (1) Thẻ Starbucks đã kích hoạt. Khi đăng ký một tài khoản, bạn sẽ nhận được một email xác minh gửi đến địa chỉ email được sử dụng để đăng ký và kích hoạt tài khoản của bạn (Vui lòng liên hệ với chúng tôi nếu bạn không nhận được email xác minh việc đăng ký trong vòng 24 giờ). Trong quá trình đăng ký, bạn có thể được yêu cầu cung cấp các thông tin sau: tên người dùng, mật khẩu, địa chỉ email (được yêu cầu để nhận tất cả Ưu Đãi đủ điều kiện), địa chỉ liên lạc, số điện thoại, số Thẻ Starbucks đã kích hoạt, mã bảo mật thẻ (CSC), ngày sinh, tên và sở thích của bạn về việc nhận các tin tức, khuyến mãi và ưu đãi của chương trình.`,
  'termAndCondition.content21': `Bạn chỉ có thể có duy nhất một (1) tài khoản Starbucks Rewards™. Nếu bạn có nhiều hơn một (1) tài khoản Starbucks Rewards™, Starbucks có toàn quyền quyết định rằng bạn đã vi phạm các Điều khoản Sử dụng này. Theo quyết định riêng của mình, Starbucks có thể tạm dừng, hủy bỏ hoặc kết hợp các tài khoản trùng lặp. Trong trường hợp việc tham gia Chương trình Starbucks Rewards™ của bạn bị chấm dứt, tất cả các ưu đãi và Điểm Ngôi Sao đã tích lũy trong tài khoản của bạn đều bị vô hiệu.`,
  'termAndCondition.content22': `Tài khoản phải có ít nhất 01 giao dịch mua hàng trong mỗi năm (mười hai (12) tháng) kể từ khi đăng ký thành công để được tận hưởng các quyền lợi của Chương trình Starbucks Rewards™.`,
  'termAndCondition.content23': `Thẻ Starbucks có thể được đăng ký vào tài khoản của bạn tại bất cứ thời điểm nào. Bạn có thể đăng ký/thêm tối đa hai mươi (20) Thẻ Starbucks đã kích hoạt vào tài khoản của bạn. Số dư thanh toán trong một Thẻ Starbucks đã đăng ký (Thẻ Starbucks đã chuyển tiền) có thể được chuyển toàn bộ sang một Thẻ Starbucks đã đăng ký khác trong cùng một tài khoản (Chuyển Tiền) nhưng không được phép chuyển một phần số dư. Thẻ Starbucks đã chuyển tiền sẽ trở thành Thẻ không còn tiền nhưng Thẻ Starbucks này vẫn hoạt động để tiếp tục nạp tiền và sử dụng. Thẻ Starbucks không còn hiệu lực được xác định là không Thẻ tích lũy Điểm Ngôi Sao. Việc chuyển tiền giữa các Thẻ Starbucks chỉ được thực hiện bởi cá nhân bạn thông qua website của chúng tôi hoặc Ứng Dụng Di Động (Chuyển Tiền).`,
  'termAndCondition.content24': `Tích Lũy Điểm Ngôi Sao`,
  'termAndCondition.content25': `Những ưu đãi bạn sẽ nhận được thông qua Chương trình Starbucks Rewards™ được dựa trên số lượng "Điểm Ngôi Sao" mà bạn tích lũy được.`,
  'termAndCondition.content26': `Bạn có thể tích lũy Điểm Ngôi Sao bằng cách:`,
  'termAndCondition.content27': `• Mua hàng và thanh toán bằng thẻ Starbucks: Bạn sử dụng Thẻ Starbucks đã kích hoạt và đã được đăng ký vào Tài Khoản Starbucks Rewards™ của bạn để thanh toán cho việc mua các sản phẩm tại cửa hàng Starbucks; hoặc mua hàng thông qua tính năng “Đặt Hàng Trực Tuyến và Nhận Hàng Tại Cửa Hàng” (“O&PU") hoặc chức năng “Đặt Hàng Trực Tuyến và Giao Hàng Tận Nơi” (“Starbucks Delivers” hoặc “SBD”)) trên Ứng Dụng Di Động (như được mô tả dưới đây).
  Xin lưu ý rằng khoản chi tiêu của bạn khi thanh toán bằng Thẻ Starbucks tại cửa hàng Starbucks chỉ được quy đổi thành Điểm Ngôi Sao và tích lũy vào tài khoản Starbucks Rewards™ của bạn khi bạn xuất trình Thẻ Starbucks hoặc mã vạch tương ứng trên Ứng Dụng Di Động để nhân viên Starbucks quét tại thời điểm thanh toán. Khoản chi tiêu của bạn khi thanh toán bằng Thẻ Starbucks thông qua chức năng “Đặt và Nhận Hàng” và “Giao Hàng Tận Nơi” trên Ứng Dụng Di Động sẽ tự động được quy đổi thành Điểm Ngôi Sao và tích lũy vào tài khoản Starbucks Rewards™ của bạn ngay sau khi việc thanh toán của bạn được hoàn thành.`,
  'termAndCondition.content28': `• Mua hàng và thanh toán qua ứng dụng di động Grab (“Ứng Dụng Grab”): Bạn mua và thanh toán cho các sản phẩm từ cửa hàng Starbucks thông qua Ứng Dụng Grab và đã thực hiện liên kết thành công tài khoản Starbucks Rewards™ của bạn với tài khoản Grab của bạn (“Tài Khoản Grab”) trên Ứng Dụng Grab này (như được mô tả chi tiết dưới đây) cho mục đích tích lũy Điểm Ngôi Sao theo Chương trình Starbucks Rewards™.
  Khoản chi tiêu của bạn cho việc thanh toán các sản phẩm Starbucks qua Ứng Dụng Grab sẽ tự động được quy đổi thành Điểm Ngôi Sao và tích lũy vào tài khoản Starbucks Rewards™ của bạn ngay sau khi việc thanh toán của bạn được hoàn thành.`,
  'termAndCondition.content29': `Nguyên tắc quy đổi Điểm Ngôi Sao dựa trên chi tiêu của bạn:`,
  'termAndCondition.content30': `• Thanh toán bằng Thẻ Starbucks: Số Điểm Ngôi Sao bạn nhận được sẽ dựa trên giá trị mua hàng của bạn theo tỉ lệ một (1) Điểm Ngôi Sao cho mỗi bốn mươi ngàn đồng Việt Nam (40.000 VNĐ) đã thanh toán cho giao dịch đó.`,
  'termAndCondition.content31': `• Thanh toán qua Ứng Dụng Grab: Số Điểm Ngôi Sao bạn nhận được sẽ dựa trên giá trị mua hàng của bạn theo tỉ lệ một (1) Điểm Ngôi Sao cho mỗi tám mươi ngàn đồng Việt Nam (80.000 VNĐ) đã thanh toán cho một đơn hàng (sau khi đã trừ đi các khoản ưu đãi, giảm giá và không bao gồm phí giao hàng, phí cố định hay các mức phí khác do Grab quy định).`,
  'termAndCondition.content32': `Xin lưu ý rằng, tùy theo quyết định của Starbucks, Điểm Ngôi Sao có thể được quy đổi và tích lũy ở mức phân số dựa trên giá trị mua hàng mà bạn đã thanh toán đối với mỗi giao dịch và phù hợp với Điều Khoản Sử Dụng này.`,
  'termAndCondition.content33': `Nói chung, Điểm Ngôi Sao đối với các khoản chi tiêu mua hàng của bạn đáp ứng điều kiện tích lũy sẽ được tự động thêm vào tài khoản của bạn tại thời điểm sớm nhất sau khi giao dịch được thực hiện thành công hoặc trong vòng 48 tiếng. Tùy từng thời điểm, chúng tôi, hoặc các đơn vị khác dưới sự cho phép của chúng tôi, có thể cung cấp cho bạn những khuyến mại đặc biệt mà trao cho bạn "Điểm Ngôi Sao Khuyến Mại". Điểm Ngôi Sao Khuyến Mại, chẳng hạn như Điểm Ngôi Sao “thưởng", sẽ được tặng theo quy định trong đề nghị khuyến mại áp dụng và phải tuân thủ bất cứ điều khoản bổ sung nào được quy định trong đề nghị khuyến mại.`,
  'termAndCondition.content34': `Quyền Lợi của Các Hạng Thẻ của Chương trình Starbucks Rewards™`,
  'termAndCondition.content35': `Hiện nay, có hai (2) hạng mức thẻ với lợi ích khác nhau trong Chương trình Starbucks Rewards™, được xác định theo số Điểm Ngôi Sao mà bạn tích lũy trong tài khoản của bạn mỗi giai đoạn mười hai (12) tháng. Bằng cách mở một tài khoản và đăng ký một Thẻ Starbucks đã kích hoạt, bạn sẽ tự động trở thành thành viên của Chương trình Starbucks Rewards™ ở Hạng Xanh. Nếu bạn tích lũy đủ một trăm (100) Điểm Ngôi Sao mới trong thời hạn mười hai (12) tháng kể từ ngày tham gia của bạn, bạn sẽ đủ điều kiện nâng cấp lên Hạng Vàng.`,
  'termAndCondition.content36': `Khi bạn đạt được một hạng thẻ cụ thể của Chương trình Starbucks Rewards™, bạn sẽ nhận được những lợi ích đang áp dụng cho hạng thẻ đó tại thời điểm đó.`,
  'termAndCondition.content37': `Để duy trì tất cả các lợi ích của Hạng Xanh, bạn chỉ cần đăng ký tối thiểu một (1) Thẻ Starbucks đã kích hoạt vào tài khoản của bạn.`,
  'termAndCondition.content38': `Để duy trì tất cả các lợi ích của Hạng Vàng, bạn phải tích lũy ít nhất một trăm (100) Điểm Ngôi Sao mới trong khoảng thời gian mỗi mười hai (12) tháng liên tiếp sau ngày bạn đạt được Hạng Vàng. Tại ngày kỷ niệm của ngày đạt Hạng Vàng (12 tháng) kể từ ngày bạn được nâng cấp lên Hạng Vàng, Điểm Ngôi Sao của bạn sẽ được thiết lập lại. Để thông tin cho bạn, việc thiết lập lại tại Hạng Vàng có nghĩa là số dư Điểm Ngôi Sao sau khi chia cho 25 và không vượt quá 25 Điểm Ngôi Sao. Nếu bạn không tích lũy một trăm (100) Điểm Ngôi Sao mới trong thời gian mỗi mười hai (12) tháng, bạn sẽ bị hạ xuống Hạng Xanh của Chương trình Starbucks Rewards™ và số dư Điểm Ngôi Sao của bạn sẽ tự động trả lại về giá trị không (0).`,
  'termAndCondition.content39': `Xin lưu ý rằng Điểm Ngôi Sao được tích lũy cho mỗi hạng thẻ của Chương trình Starbucks Rewards™ chỉ có giá trị trong mười hai (12) tháng kể từ ngày bạn đạt hạng thành viên cụ thể. Tuy nhiên, bất cứ Ưu Đãi nào mà bạn đã tích lũy nhưng chưa sử dụng và chưa hết hạn vẫn tiếp tục áp dụng trong tài khoản của bạn và bạn có thể sử dụng Ưu Đãi đó theo các điều kiện được quy định tại đây.`,
  'termAndCondition.content40': `Bạn có thể xem hoặc theo dõi số dư Điểm Ngôi Sao và Ưu Đãi hiện có của bạn tại `,
  'termAndCondition.content41': ` hoặc Ứng Dụng Di Động. Starbucks bảo lưu quyền thay đổi số lượng mức lợi ích và thay đổi những lợi ích và Ưu Đãi có liên quan đến mỗi mức lợi ích theo quyết định chỉ riêng của Starbucks tại bất cứ thời điểm nào.`,
  'termAndCondition.content42': `Hạng Xanh`,
  'termAndCondition.content43': `Bằng cách đăng ký Thẻ Starbucks đã kích hoạt của bạn, bạn tự động đạt được thành viên “Hạng Xanh" của Chương trình Starbucks Rewards™, hoặc đối với trường hợp bạn không tích lũy đủ một trăm (100) Điểm Ngôi Sao trong thời hạn mười hai (12) tháng cho việc nâng cấp lên thành viên Thẻ Vàng, bạn vẫn là thành viên Hạng Xanh nhưng Tổng Số Điểm Ngôi Sao sẽ trở về giá trị không (0). Ưu Đãi bao gồm:`,
  'termAndCondition.content44': `1. Ưu Đãi Mua-Một-Tặng-Một (“Ưu Đãi BOGO”). Bạn sẽ nhận được một (1) ưu đãi Mua-Một-Tặng-Một khi mua suất đồ uống pha chế cỡ vừa (tall) hoặc cỡ lớn hơn sau khi bạn đã tích lũy được năm (5) Điểm Ngôi Sao đầu tiên, như mô tả dưới đây:`,
  'termAndCondition.content45': `• Coupon Ưu Đãi BOGO của bạn sẽ được nạp tự động vào tài khoản của bạn trong vòng bốn mươi tám (48) giờ sau khi bạn tích lũy được năm (5) Điểm Ngôi Sao đầu tiên vào một tài khoản Starbucks Rewards. coupon Ưu Đãi BOGO sẽ hết hạn sau một (1) tháng kể từ ngày coupon Ưu Đãi BOGO đó được nạp vào tài khoản của bạn. Bạn phải xuất trình Thẻ Starbucks đã đăng ký hoặc mã vạch tương ứng trên Ứng Dụng Di Động của bạn cho nhân viên pha chế tại cửa hàng Starbucks hoặc được tiến hành trên Ứng Dụng Di Động để sử dụng coupon và nhận Ưu Đãi BOGO của bạn. Ưu Đãi BOGO có thể được nhận tại cửa hàng Starbucks (có thể thông qua tính năng O&PU trên Ứng Dụng Di Động để đặt trước) hoặc thông qua tính năng SBD trên Ứng Dụng Di Động để được giao tận nơi, và không áp dụng cho đồ uống đóng chai sẵn.`,
  'termAndCondition.content46': `2. Ưu Đãi Sinh Nhật. Bạn sẽ nhận được một (1) phần bánh miễn phí trong tháng sinh nhật của bạn như mô tả dưới đây:`,
  'termAndCondition.content47': `• Coupon Ưu Đãi Sinh Nhật sẽ được nạp tự động vào tài khoản của bạn vào ngày đầu tiên của tháng sinh nhật của bạn. Coupon Ưu Đãi Sinh Nhật hết hạn vào ngày cuối cùng trong tháng sinh nhật của bạn. Bạn phải xuất trình Thẻ Starbucks đã đăng ký hoặc mã vạch tương ứng trên Ứng Dụng Di Động cho nhân viên pha chế tại cửa hàng Starbucks  để nhận coupon và nhận Ưu Đãi Sinh Nhật của bạn. Bạn chỉ nhận được một (1) Ưu Đãi Sinh Nhật bất kể số lượng Thẻ Starbucks đã đăng ký mà bạn có thể sở hữu. Ưu Đãi Sinh Nhật có thể được nhận tại cửa hàng Starbucks (có thể thông qua tính năng O&PU trên Ứng Dụng Di Động để đặt trước) hoặc thông qua tính năng SBD trên Ứng Dụng Di Động để được giao tận nơi.`,
  'termAndCondition.content48': `Hạng Vàng`,
  'termAndCondition.content49': `Khi bạn tích lũy được ít nhất một trăm (100) Điểm Ngôi Sao trong khoảng thời gian mười hai (12) tháng, bạn sẽ được nâng cấp lên hạng “Hạng Thẻ Vàng” và số dư Điểm Ngôi Sao của bạn sẽ được thiết lập lại. Để thông tin cho bạn, việc thiết lập lại tại Hạng Vàng có nghĩa là số dư Điểm Ngôi Sao sau khi chia cho 25 và không vượt quá 25 Điểm Ngôi Sao. Suốt mỗi khoảng thời gian mười hai (12) tháng từ ngày bạn được nâng cấp lên Hạng Vàng và/hoặc duy trì Hạng Vàng, những lợi ích đối với Hạng Thẻ Vàng bao gồm:`,
  'termAndCondition.content50': `1. Ưu Đãi Hạng Xanh. Bạn vẫn có thể nhận bất cứ Ưu Đãi Hạng Xanh nào chưa sử dụng và chưa hết hạn trước khi bạn được nâng cấp lên Hạng Vàng, tuy nhiên bạn phải sử dụng Ưu Đãi chưa được sử dụng đó trong thời hạn được quy định.`,
  'termAndCondition.content51': `2. Ưu Đãi Sinh Nhật. Bạn sẽ nhận được một (1) phần bánh miễn phí trong tháng sinh nhật của bạn như mô tả dưới đây:`,
  'termAndCondition.content52': `• Coupon Ưu Đãi Sinh Nhật sẽ được nạp tự động vào tài khoản của bạn vào ngày đầu tiên của tháng sinh nhật của bạn. Coupon Ưu Đãi Sinh Nhật hết hạn vào ngày cuối cùng của tháng sinh nhật bạn. Bạn phải xuất trình Thẻ Starbucks đã đăng ký hoặc mã vạch tương ứng trong Ứng Dụng Di Động cho nhân viên pha chế tại cửa hàng Starbucks để nhận Ưu Đãi Sinh Nhật của bạn. Bạn chỉ nhận được một (1) Ưu Đãi Sinh Nhật bất kể số lượng Thẻ Starbucks đã đăng ký mà bạn có thể sở hữu. Ưu Đãi Sinh Nhật có thể được nhận tại cửa hàng Starbucks (có thể thông qua tính năng O&PU trên Ứng Dụng Di Động để đặt trước) hoặc thông qua tính năng SBD trên Ứng Dụng Di Động để được giao tận nơi.`,
  'termAndCondition.content53': `3. Ưu Đãi Mua-Một-Tặng-Một (“Ưu Đãi BOGO”). Bạn sẽ nhận được một (1) ưu đãi Mua-Một-Tặng-Một khi mua suất đồ uống pha chế cỡ vừa (tall) hoặc cỡ lớn hơn, như mô tả dưới đây:`,
  'termAndCondition.content54': `• Coupon Ưu Đãi BOGO của bạn sẽ được nạp tự động vào tài khoản của bạn trong vòng bốn mươi tám (48) giờ sau khi bạn đạt Hạng Vàng. Coupon Ưu Đãi BOGO sẽ hết hạn sau một (1) tháng kể từ ngày Ưu Đãi BOGO đó được nạp vào tài khoản của bạn. Bạn phải xuất trình Thẻ Starbucks đã đăng ký hoặc mã vạch tương ứng trên Ứng Dụng Di Động của bạn cho nhân viên pha chế tại cửa hàng Starbucks hoặc được tiến hành trên Ứng Dụng Di Động để sử dụng Coupon và nhận Ưu Đãi BOGO của bạn . Ưu Đãi BOGO có thể được nhận tại cửa hàng Starbucks (có thể thông qua tính năng O&PU trên Ứng Dụng Di Động để đặt trước) hoặc thông qua năng SBD trên Ứng Dụng Di Động để được giao tận nơi, và không áp dụng cho đồ uống đóng chai sẵn.`,
  'termAndCondition.content55': `4. Ưu Đãi Đồ Uống Miễn Phí. Bạn sẽ nhận được một (1) phần đồ uống miễn phí cỡ vừa (tall) theo sự lựa chọn của bạn cho mỗi hai mươi lăm (25) Điểm Ngôi Sao tăng thêm sau khi bạn tích lũy được trên một trăm (100) Điểm Ngôi Sao cần thiết để nâng cấp lên hạng Hạng Thẻ Vàng, như được mô tả dưới đây:`,
  'termAndCondition.content56': `• Coupon Ưu Đãi Đồ Uống Miễn Phí của bạn sẽ được nạp tự động vào tài khoản của bạn trong vòng bốn mươi tám (48) giờ sau khi bạn tích lũy được mỗi hai mươi lăm (25) Điểm Ngôi Sao tăng thêm. Coupon Ưu Đãi Đồ Uống Miễn Phí hết hạn sau một (1) tháng kể từ ngày Ưu Đãi Đồ Uống Miễn Phí được nạp vào tài khoản của bạn. Bạn phải xuất trình Thẻ Starbucks đã đăng ký hoặc mã vạch tương ứng trên Ứng Dụng Di Động của bạn cho nhân viên pha chế tại cửa hàng Starbucks hoặc được tiến hành trên Ứng Dụng Di Động để sử dụng Coupon và nhận Ưu Đãi Đồ Uống Miễn Phí của bạn. Bạn sẽ chỉ nhận được một (1) phần Ưu Đãi Đồ Uống Miễn Phí cho mỗi hai mươi lăm (25) Điểm Ngôi Sao tăng thêm mà bạn tích lũy. Ưu Đãi Đồ Uống Miễn Phí có thể được nhận tại cửa hàng Starbucks (có thể thông qua tính năng O&PU trên Ứng Dụng Di Động để đặt trước) hoặc thông qua tính năng SBD trên Ứng Dụng Di Động để được giao tận nơi, và không áp dụng cho đồ uống đóng chai sẵn.`,
  'termAndCondition.content57': `5. Ưu Đãi Đặc Biệt và/hoặc Thư Mời: theo quyết định chỉ riêng của Starbucks, bạn có thể nhận thêm (các) đề nghị và/hoặc thư mời đặc biệt để tham gia vào (các) sự kiện được tổ chức bởi hoặc cho Starbucks bằng email hoặc thư. Bạn phải đồng ý nhận các đề nghị khuyến mại và tiếp thị từ Starbucks và có thể được yêu cầu cung cấp địa chỉ email, điện thoại và địa chỉ hợp lệ để nhận các đề nghị và thư mời đặc biệt của bạn. Đề nghị và thư mời đặc biệt có thể được tùy chỉnh dựa trên thói quen và sở thích mua hàng. Đề nghị đặc biệt có thể bao gồm giảm giá định kỳ đối với món ăn, đồ uống và hàng hóa. Đề nghị đặc biệt có thể có những hạn chế nhất định bao gồm cả ngày hết hạn và thời gian nhận thưởng với thời hạn ngắn. Đề nghị đặc biệt là dành riêng cho cá nhân bạn và không thể được chia sẻ, sao chép hoặc chuyển giao. Bạn có thể được yêu cầu xuất trình Thẻ Starbucks đã đăng ký hoặc mã vạch tương ứng trên Ứng Dụng Di Động của bạn để nhận các đề nghị đặc biệt. Vui lòng xem kỹ từng đề nghị để biết thêm về chi tiết, ngày hết hạn, các hạn chế và giới hạn cụ thể khi đề nghị như vậy được tặng vào tài khoản của bạn.`,
  'termAndCondition.content58': `Bạn chịu trách nhiệm cho việc cập nhật tài khoản Starbucks Rewards™ của bạn và đảm bảo tất cả các thông tin mà bạn cung cấp trong tài khoản của bạn là chính xác và cập nhật tại mọi thời điểm, bao gồm email và địa chỉ gửi thư tại `,
  'termAndCondition.content59': `. Nếu bất cứ thông tin bạn cung cấp không chính xác hoặc không được cập nhật, bạn có thể không nhận được (các) đề nghị và/hoặc thư mời đặc biệt. Bạn có thể xem và theo dõi (các) đề nghị và/hoặc thư mời đặc biệt được cung cấp cho bạn tại `,
  'termAndCondition.content60': ` hoặc thông qua Ứng Dụng Di Động.`,
  'termAndCondition.content61': `Tính năng “Đặt Hàng Trực Tuyến & Nhận Hàng Tại Cửa Hàng” trên Ứng Dụng Di Động`,
  'termAndCondition.content62': `Tính năng Đặt và Nhận Hàng Trực Tuyến & Nhận Hàng Tại Cửa Hàng (“Order & Pick Up” hoặc “O&PU”) này trên Ứng Dụng Di Động cho phép bạn đặt hàng và thanh toán trước khi đến cửa hàng Starbucks đã chọn. Bằng việc sử dụng tính năng này, bạn có thể gửi đơn đặt hàng của bạn đến nhân viên pha chế để thực hiện và giao hàng cho bạn tại khu vực giao hàng của cửa hàng vào thời gian giao hàng bạn đã chọn mà không cần xếp hàng hay trả thêm phí dịch vụ cho việc sử dụng tính năng này. Tính năng này chỉ được tích hợp vào Ứng Dụng Di Động và áp dụng cho (các) thành viên của Chương trình Starbucks Rewards™. Bạn được yêu cầu bật dịch vụ định vị trên thiết bị của mình và đảm bảo cập nhật mới nhất cho Ứng Dụng Di Động để khám phá chức năng mới này.`,
  'termAndCondition.content63': `Tính năng O&PU này hiện có trên Ứng Dụng Di Động để bạn sử dụng thông qua các bước đơn giản sau:`,
  'termAndCondition.content64': `1. Truy cập phiên bản mới nhất của Ứng Dụng Di Động và đăng nhập vào tài khoản của bạn.`,
  'termAndCondition.content65': `2. Chọn thẻ “Đặt món” từ thanh menu: dịch vụ định vị trên thiết bị của bạn được yêu cầu bật lên để bắt đầu sử dụng tính năng này.`,
  'termAndCondition.content66': `3. Chọn tính năng Order & Pick-up`,
  'termAndCondition.content67': `4. Chọn cửa hàng để nhận hàng:  Các món trong thực đơn cũng sẽ được cập nhật dựa trên tình trạng sẵn có sau khi cửa hàng Starbucks được chọn.`,
  'termAndCondition.content68': `5. Chọn các món ăn và đồ uống để đặt hàng: Cũng giống như tại các cửa hàng của chúng tôi, món ăn và đồ uống có thể tùy chỉnh, bao gồm ở nhiều tùy chọn để thay đổi các lựa chọn về kích cỡ, số lượng ly cà phê espresso, sữa và các lựa chọn khác. Bạn có thể xem thông tin từng món và chọn “Thêm vào giỏ” để xác nhận.`,
  'termAndCondition.content69': `6. Xem lại đơn đặt hàng và thời gian chuẩn bị món dự kiến bằng cách nhấn vào "Tiếp tục": Bạn có thể sử dụng coupon đã được nạp vào tài khoản của bạn để thanh toán. Thông tin chi tiết về đơn đặt hàng của bạn, thời gian nhận hàng dự kiến, cửa hàng nhận hàng và tổng số tiền thanh toán của bạn sẽ được hiển thị để bạn xem lại và xác nhận.`,
  'termAndCondition.content70': `7. Tiến hành thanh toán: Thanh toán được thực hiên bằng một Thẻ Starbucks đã đăng ký trong tài khoản của bạn. Bạn có thể lựa chọn Thẻ Starbucks muốn sử dụng để thanh toán.`,
  'termAndCondition.content71': `8. Hoàn thành đơn đặt hàng của bạn bằng cách nhấn vào “Đặt món”: cửa sổ thông báo với Biên lai điện tử (eReceipt) cho đơn đặt hàng của bạn và bộ đếm ngược thời gian nhận hàng dự kiến sẽ được hiển thị. Bạn cũng có thể kiểm tra thông tin này tại Lịch Sử Giao Dịch.`,
  'termAndCondition.content72': `9. Đến cửa hàng Starbucks đã chọn để nhận đồ ăn và thức uống của bạn: Các đơn đặt hàng ngay lập tức được gửi đến cửa hàng Starbucks đã chọn, nơi nhân viên pha chế sẽ bắt đầu chuẩn bị các đồ ăn và thức uống. Khi nhân viên pha chế đã hoàn thành đơn đặt hàng của bạn, bạn sẽ nhận được thông báo (push notification) được gửi qua Ứng Dụng Di Động để đến nhận hàng trong thời gian nhận hàng dự kiến và thưởng thức đồ ăn / đồ uống của bạn.`,
  'termAndCondition.content73': `Trước tiên, Ứng Dụng Di Động sẽ mặc định đơn đặt hàng gần nhất của bạn và cửa hàng Starbucks sẵn có gần nhất trong vòng bán kính năm mươi (50) km tính từ vị trí hiện tại của bạn, cũng như hiển thị tình trạng sẵn có của thực đơn hiện tại và thời gian nhận hàng dự kiến cho đơn đặt hàng của bạn tại cửa hàng đó. Bạn có thể thay đổi cửa hàng bằng cách chạm vào bản đồ để lựa chọn hoặc sử dụng nút "Nhận Tại" trong ứng dụng. Khi đơn đặt hàng của bạn đã được gửi, bạn không thể thay đổi cửa hàng nhận hàng. Bạn chỉ có thể đặt hàng sau khi cửa hàng Starbucks được chọn mở cửa và đến 30 phút trước khi cửa hàng Starbucks đó đóng cửa.`,
  'termAndCondition.content74': `Hầu hết tất cả đồ ăn và thức uống Starbucks yêu thích của bạn đều được cung cấp thông qua tính năng O&PU ngoại trừ đồ uống có cồn, nước đóng chai/lon sẵn, hàng hóa lưu niệm, cà phê hạt. Thực đơn cho lựa chọn của bạn sẽ liệt kê tất cả các đồ ăn và thức uống có thể cung cấp với cùng một mức giá tại cửa hàng Starbucks được chọn. Với một vài thao tác trên thiết bị của bạn, bạn có thể tùy chỉnh đồ uống yêu thích của bạn và lựa chọn để được phục vụ đồ ăn được làm nóng, như thể bạn có thể yêu cầu tại cửa hàng.`,
  'termAndCondition.content75': `Khi bạn đặt đơn hàng, bạn sẽ được thông báo thời gian nhận hàng dự kiến mà không có bất kỳ lựa chọn nào để bạn thay đổi hoặc yêu cầu cụ thể thời gian nhận hàng dự kiến này. Thời gian sẽ thay đổi tùy thuộc vào nhiều yếu tố khác nhau bao gồm ở tình trạng sẵn sàng của cửa hàng Starbucks, số lượng đồ ăn và thức uống được đặt và thời điểm trong ngày. Bạn có thể đến cửa hàng Starbucks được chọn trong khoảng thời gian nhận hàng dự kiến.`,
  'termAndCondition.content76': `Việc thanh toán cho đơn đặt hàng của bạn qua tính năng O&PU phải được hoàn thành bằng Thẻ Starbucks đã đăng ký thông qua Ứng Dụng Di Động. Số tiền trả trước của bạn trên Thẻ Starbucks đã đăng ký đó sẽ được hệ thống của chúng tôi khấu trừ cho giao dịch đặt hàng này. Starbucks cũng có các tiêu chuẩn bảo mật riêng cho các giao dịch khấu trừ này để đảm bảo thông tin bảo mật và an toàn. Trong trường hợp phát sinh giao dịch không mong muốn, vui lòng liên hệ với Trung tâm Chăm sóc Khách hàng Starbucks Rewards™ của chúng tôi để chúng tôi có thể hỗ trợ kịp thời.`,
  'termAndCondition.content77': `Sau khi đặt hàng qua tính năng O&PU, đơn đặt hàng sẽ được gửi ngay đến cửa hàng Starbucks được chọn và nhân viên pha chế của chúng tôi sẽ bắt đầu chuẩn bị đơn đặt hàng này. Bạn không thể thay đổi cửa hàng Starbucks được chọn, sửa đổi hoặc hủy đơn đặt hàng, trả lại đồ ăn / thức uống đã đặt, yêu cầu hoàn tiền trong bất kỳ trường hợp nào hoặc đặt bất kỳ đơn hàng nào khác tại cùng một thời điểm.`,
  'termAndCondition.content78': `Vui lòng đảm bảo các thông báo được cho phép hiển thị trong cài đặt trong Ứng Dụng Di Động và thiết bị của bạn để nhận thông báo đẩy khi đơn đặt hàng của bạn đã sẵn sàng để nhận tại cửa hàng Starbucks được chọn trong thời gian nhận hàng dự kiến. Bạn thừa nhận rằng Starbucks không thể duy trì chất lượng của các đồ ăn / thức uống được đặt như bạn mong muốn trong trường hợp bạn đến nhận hàng muộn hơn thời gian nhận hàng dự kiến, mặc dù đã nhận được thông báo đẩy và đồng ý giữ cho Starbucks vô hại khỏi bất kỳ trách nhiệm pháp lý nào trong trường hợp này. Tuy nhiên, trong khả năng có thể, chúng tôi luôn cố gắng mang đến những sản phẩm chất lượng nhất cho bạn.`,
  'termAndCondition.content79': `Bạn cũng có thể tích lũy Điểm Ngôi Sao cho việc mua hàng được thực hiện thông qua tính năng O&PU này.`,
  'termAndCondition.content80': `Bạn có thể liên hệ với Trung tâm Chăm sóc Khách hàng Starbucks Rewards™ của chúng tôi theo thông tin bên dưới nếu bạn gặp bất kỳ vấn đề nào trong quá trình sử dụng tính năng O&PU này.`,
  'termAndCondition.content81': `Tính năng “Đặt Hàng Trực Tuyến và Giao Hàng Tận Nơi” trên Ứng Dụng Di Động`,
  'termAndCondition.content82': `Tính năng “Đặt Hàng Trực Tuyến và Giao Hàng Tận Nơi” (“Starbucks Delivers” hoặc “SBD”) này trên Ứng Dụng Di Động cho phép bạn đặt hàng và thanh toán trước tiền mua hàng và phí vận chuyển tương ứng, và nhận hàng bởi bạn hoặc người được bạn chỉ định và cung cấp cho chúng tôi thông tin liên hệ để giao hàng (gọi chung là “Người Nhận Hàng”) tại địa chỉ mà bạn lựa chọn (hoặc cung cấp cho chúng tôi thông qua việc chia sẻ định vị trên thiết bị của bạn) mà không cần phải đến nhận món tại khu vực giao hàng của cửa hàng Starbucks. Khi sử dụng tính năng SBD này, bạn chỉ có thể đặt tối đa tám (8) món đồ ăn/thức uống cho mỗi đơn hàng. Đơn đặt hàng của bạn sẽ được gửi đến nhân viên pha chế tại cửa hàng Starbucks gần nhất (trong phạm vi bán kính ba (3) km kể từ vị trí yêu cầu giao hàng), và chúng tôi sẽ chỉ định đối tác cung cấp dịch vụ vận chuyển ("Đối Tác Giao Hàng”) để giao hàng đến Người Nhận Hàng đối với đơn hàng mà bạn đã hoàn thành việc thanh toán.`,
  'termAndCondition.content83': `Tính năng SBD này chỉ được tích hợp vào Ứng Dụng Di Động và áp dụng cho (các) thành viên của Chương trình Starbucks Rewards™. Bạn được yêu cầu bật dịch vụ định vị trên thiết bị của mình và đảm bảo cập nhật mới nhất cho Ứng Dụng Di Động để khám phá tính năng mới này.`,
  'termAndCondition.content83.2': `Tính năng SBD này hiện có trên Ứng Dụng Di Động để bạn sử dụng thông qua các bước đơn giản sau:`,
  'termAndCondition.content84': `1. Truy cập phiên bản mới nhất của Ứng Dụng Di Động và đăng nhập vào tài khoản của bạn.`,
  'termAndCondition.content85': `2. Chọn thẻ “Đặt món” từ thanh menu.`,
  'termAndCondition.content86': `3. Chọn mục “Starbucks Delivers”.`,
  'termAndCondition.content87': `4. Chia sẻ định vị hoặc lựa chọn địa chỉ và thông tin nhận hàng: Ứng Dụng Di Động sẽ tự động tìm kiếm và lựa chọn cửa hàng gần nhất trong bán kính ba (3) km với địa chỉ giao hàng mà bạn đã chia sẻ hoặc lựa chọn.`,
  'termAndCondition.content88': `5. Chọn các món ăn và đồ uống để đặt hàng: Cũng giống như tại các cửa hàng của chúng tôi, món ăn và đồ uống có thể tùy chỉnh, bao gồm nhiều tùy chọn để thay đổi các lựa chọn về kích cỡ, số lượng ly cà phê espresso, sữa và các lựa chọn khác. Các món ăn và đồ uống sẽ được cập nhật dựa trên tình trạng sẵn có tại cửa hàng Starbucks được lựa chọn tại bước 4 trên đây. Bạn có thể xem thông tin từng món và chọn “Thêm vào giỏ” để xác nhận.`,
  'termAndCondition.content89': `6. Xem lại đơn đặt hàng và xác nhận bằng cách nhấn vào "Tiếp tục ": bạn có thể sử dụng coupon đã được nạp vào tài khoản của bạn để thanh toán. Thông tin chi tiết về đơn đặt hàng của bạn, địa chỉ, số điện thoại nhận hàng và tổng số tiền thanh toán của bạn (bao gồm cả phí vận chuyển) sẽ được hiển thị để bạn xem lại và xác nhận. Xin lưu ý, trong trường hợp bạn sử dụng tính năng SBD này chỉ cho việc nhận các Ưu Đãi Ưu Đãi Sinh Nhật hoặc Ưu Đãi Đồ Uống Miễn Phí trong một (1) đơn đặt hàng, bạn vẫn phải xác nhận phí vận chuyển theo thông tin hiển thị tương ứng trước khi thực hiện việc thanh toán theo bước (7) ngay dưới đây.`,
  'termAndCondition.content90': `7. Tiến hành thanh toán: Thanh toán được thực hiên bằng một Thẻ Starbucks đã đăng ký trong tài khoản của bạn. Bạn có thể lựa chọn Thẻ Starbucks muốn sử dụng để thanh toán.`,
  'termAndCondition.content91': `8. Hoàn thành đơn đặt hàng của bạn bằng cách nhấn vào “Đặt món”: cửa sổ thông báo với Biên lai điện tử (eReceipt) cho đơn đặt hàng của bạn sẽ được hiện lên ở trang chủ với thông tin cập nhật tình trạng đơn hàng của bạn và khoảng thời gian dự kiến giao hàng tới Người Nhận Hàng. Bạn cũng có thể kiểm tra thông tin này tại Lịch Sử Giao Dịch.`,
  'termAndCondition.content92': `9. Nhận đồ ăn và thức uống tại địa chỉ bạn đã chọn: các đơn đặt hàng ngay lập tức được gửi đến cửa hàng Starbucks đã chọn, nơi nhân viên pha chế sẽ bắt đầu chuẩn bị các đồ ăn và thức uống. Đối Tác Giao Hàng sẽ tới cửa hàng để nhận món và giao đến cho Người Nhận Hàng. Bạn có thể theo dõi quá trình di chuyển của Đối Tác Giao Hàng thông qua Ứng Dụng Di Động. Bất kể việc thông báo khoảng thời gian dự kiến giao hàng cho Người Nhận Hàng như được hiển thị cho bạn trên Ứng Dụng Di Động, bạn đồng ý rằng trên thực tế, khoảng thời gian dự kiến này chỉ để tham khảo và có thể bị thay đổi hợp lý tùy thuộc vào một số yếu tố, bao gồm nhưng không hạn chế ở tình trạng sẵn sàng của cửa hàng Starbucks liên quan, số lượng món ăn và thức uống được đặt, thời điểm đặt hàng, tình trạng giao thông của tuyến giao hàng, thời tiết, hạn chế ra vào của khu vực giao hàng.`,
  'termAndCondition.content93': `Trước tiên, Ứng Dụng Di Động sẽ mặc định chọn cửa hàng Starbucks đang mở cửa có vị trí gần nhất trong vòng bán kính ba (3) km tính từ địa chỉ giao hàng mà bạn chọn, cũng như hiển thị tình trạng sẵn có của thực đơn hiện có của cửa hàng đó tại thời điểm đó để bạn lựa chọn và thời gian giao hàng dự kiến. Bạn không thể thay đổi cửa hàng đã được chọn mặc định bởi Ứng Dụng Di Động. Bạn chỉ có thể đặt hàng sau khi có cửa hàng Starbucks trong phạm vi quy định mở cửa và đến ba mươi (30) phút trước khi cửa hàng Starbucks đó đóng cửa.`,
  'termAndCondition.content94': `Hầu hết tất cả đồ ăn và thức uống Starbucks yêu thích của bạn đều được cung cấp thông qua tính năng SBD ngoại trừ đồ uống có cồn, nước đóng chai/lon sẵn, hàng hóa lưu niệm, cà phê hạt. Thực đơn cho lựa chọn của bạn sẽ liệt kê tất cả các đồ ăn và thức uống có thể cung cấp với cùng một mức giá tại cửa hàng Starbucks được chọn. Với một vài thao tác trên thiết bị của bạn, bạn có thể tùy chỉnh đồ uống yêu thích của bạn và lựa chọn để được phục vụ đồ ăn được làm nóng, như thể bạn có thể yêu cầu tại cửa hàng. Xin lưu ý, đồ ăn được làm nóng theo yêu cầu của bạn, đồ ăn đã làm nóng có thể bị nguội đi trong quá trình vận chuyển tùy thuộc vào khoảng cách và tình trạng giao thông, thời tiết, và các yếu tố khác, và do vậy, bạn xác nhận và đồng ý rằng chúng tôi không phải chịu bất cứ trách nhiệm gì đối với việc đồ ăn bị nguội đi như vậy.”`,
  'termAndCondition.content95': `Phí vận chuyển sẽ được áp dụng đối với từng đơn hàng được đặt thông qua tính năng SBD. Xin lưu ý, phí vận chuyển có thể thay đổi tùy từng thời điểm và tùy quãng đường giao hàng đến Người Nhận Hàng, và sẽ được hiển thị rõ ràng (cùng với các thông tin chi tiết khác về đơn đặt hàng) để bạn xem lại và thực hiện bước xác nhận và tiến hành thanh toán. Việc thanh toán cho đơn đặt hàng của bạn qua tính năng SBD phải được hoàn thành bằng Thẻ Starbucks đã đăng ký thông qua Ứng Dụng Di Động. Số tiền trả trước của bạn trên Thẻ Starbucks đã đăng ký đó sẽ được hệ thống của chúng tôi khấu trừ cho giao dịch mua hàng theo đơn đặt hàng đã tiến hành thanh toán của bạn. Starbucks cũng có các tiêu chuẩn bảo mật riêng cho các giao dịch khấu trừ này để đảm bảo thông tin bảo mật và an toàn. Trong trường hợp phát sinh giao dịch không mong muốn, vui lòng liên hệ với Trung Tâm Dịch Vụ Khách Hàng của chúng tôi để chúng tôi có thể hỗ trợ kịp thời.`,
  'termAndCondition.content96': `Sau khi đã có Đối Tác Giao Hàng xác nhận việc nhận và giao hàng theo đơn đặt hàng của bạn để giao cho Người Nhận Hàng và bạn đã hoàn thành bước tiến hành thanh toán qua tính năng SBD, bạn sẽ nhận được thông báo (eNotification) xác nhận đơn đặt hàng, và đơn đặt hàng sẽ được gửi ngay đến cửa hàng Starbucks được chọn mặc định bởi Ứng Dụng Di Động và nhân viên pha chế của chúng tôi sẽ bắt đầu chuẩn bị đơn đặt hàng này. Bạn không thể thay đổi cửa hàng Starbucks được chọn, sửa đổi hoặc hủy đơn, trả lại đồ ăn/thức uống đã đặt, yêu cầu hoàn tiền đối với đơn đặt hàng đã được xác nhận và tiến hành thanh toán, trừ trường hợp bạn có thể yêu cầu hoàn tiền trong trường hợp không giao được hàng hoặc hàng bị hỏng, vỡ, đổ, móp méo đến mức không thể sử dụng do lỗi của chúng tôi hoặc Đối Tác Giao Hàng. Hơn nữa, tại một thời điểm bạn chỉ có thể đặt một (1) đơn hàng SBD, nghĩa là bạn chỉ có thể đặt một (1) đơn hàng SBD tiếp theo sau khi có xác nhận giao hàng thành công đối với đơn hàng SBD trước đó của bạn. Tuy nhiên, tại cùng thời điểm, bạn có thể đặt thêm số lượng không giới hạn đơn hàng O&PU cùng với một (1) đơn hàng SBD.`,
  'termAndCondition.content97': `Khi Đối Tác Giao Hàng giao hàng cho bạn, vui lòng kiểm tra món theo đơn đặt hàng tương ứng tại thời điểm giao nhận. Trường hợp hàng được giao đến Người Nhận Hàng bởi Đối Tác Giao Hàng trong tình trạng bị hỏng, vỡ, đổ, móp méo đến mức không thể sử dụng được và bạn muốn từ chối nhận hàng, hoặc hàng giao bị thiếu, hãy chụp ảnh để lưu lại bằng chứng hàng được giao bởi Đối Tác Giao Hàng tại thời điểm giao nhận, và liên hệ với Trung tâm Chăm sóc Khách hàng Starbucks Rewards™ của chúng tôi trong thời hạn hai bốn  (24) giờ làm việc kể từ khi nhận hàng từ Đối Tác Giao Hàng (“Thời hạn phản ánh”) để phản ánh, cung cấp bằng chứng và nêu yêu cầu để chúng tôi xem xét và xử lý theo quy định của Điều Khoản Sử Dụng này, và chúng tôi bảo lưu quyền đổi lại hoặc hoàn tiền theo quyết định riêng của chúng tôi đối với món hàng bạn từ chối nhận hoặc bị thiếu trên cơ sở xem xét và chấp nhận của riêng chúng tôi đối với bằng chứng mà bạn cung cấp. Xin lưu ý rằng, thời gian làm việc của Trung tâm Chăm sóc Khách hàng Starbucks Rewards™ là từ Thứ 2 – 6 (8h30 – 17h) và Thứ 7 (8h30-12h); và sau khi Đối Tác Giao Hàng xác nhận hoàn thành việc giao hàng theo đơn đặt hàng của bạn và đã hết Thời hạn phản ánh nói trên, bạn sẽ không thể yêu cầu hoàn tiền vì lý do hàng hóa bị hỏng, vỡ, đổ, móp méo như vậy.`,
  'termAndCondition.content98': `Trường hợp Người Nhận Hàng không nhận được hàng hoặc Đối Tác Giao Hàng không thể giao được hàng theo đơn đặt hàng của bạn vì lỗi từ phía bạn, bao gồm nhưng không giới hạn ở việc bạn cung cấp sai địa chỉ, chia sẻ sai định vị, cung cấp sai thông tin Người Nhận Hàng và/ hoặc Đối Tác Giao Hàng không thể liên lạc với Người Nhận Hàng sau ba (03) lần gọi, mỗi lần cách nhau năm (5) phút theo số điện thoại mà bạn đã cung cấp, hay các lý do tương tự khác, tình trạng đơn đặt hàng vẫn được ghi nhận trên Ứng Dụng Di Động là đã hoàn thành và trong trường hợp này, chúng tôi không chịu bất cứ trách nhiệm gì đối với bạn. Tuy nhiên, trong trường hợp này, bạn vẫn có thể liên hệ với Trung tâm Chăm sóc Khách hàng Starbucks Rewards™ của chúng tôi để chúng tôi hỗ trợ và xử lý yêu cầu của bạn đối với đơn hàng đó theo quyết định riêng mà chúng tôi cho là hợp lý và theo quy định của Điều Khoản Sử Dụng này.`,
  'termAndCondition.content99': `Tùy thuộc vào các thông tin và chứng cứ được bạn cung cấp đối với trường hợp phản ánh về tình trạng hàng giao nhận hoặc tình trạng không nhận được hàng theo đơn đặt hàng đã tiến hành thanh toán, chúng tôi sẽ xem xét yêu cầu của bạn trên cơ sở tùy từng trường hợp và có thể hoàn tiền cho bạn, theo quyết định của riêng mà chúng tôi cho là hợp lý và theo quy định của Điều Khoản Sử Dụng này. Để làm rõ, bạn có trách nhiệm đảm bảo thông tin nhận hàng mà bạn cung cấp cho chúng tôi (địa chỉ, tên và số điện thoại của Người Nhận Hàng, v.v.) là chính xác, đầy đủ và được cập nhật. Sau khi đơn hàng đã được xác nhận, bạn không thể sửa đổi các thông tin nhận hàng này. Vui lòng kiểm tra kĩ các thông tin nhận hàng nêu trên trước khi thực hiện bước xác nhận và tiến hành thanh toán.`,
  'termAndCondition.content100': `Việc tích lũy Điểm Ngôi Sao cho bạn đối với việc mua hàng được thực hiện thông qua tính năng SBD này được thực hiện đối với tổng giá trị đơn hàng (bao gồm cả phí vận chuyển được bao gồm trong đó).`,
  'termAndCondition.content101': `Bạn có thể liên hệ với Trung tâm Chăm sóc Khách hàng Starbucks Rewards™ của chúng tôi theo thông tin bên dưới nếu bạn gặp bất kỳ vấn đề nào trong quá trình sử dụng tính năng SBD này.`,
  'termAndCondition.content102': `Tính năng liên kết Tài khoản Starbucks Rewards™ với Tài Khoản Grab`,
  'termAndCondition.content103': `Bạn có thể mua hàng từ các cửa hàng Starbucks thông qua Ứng Dụng Grab và bạn có thể lựa chọn liên kết Tài Khoản Grab với tài khoản Starbucks Rewards™ của bạn bằng cách sử dụng tính năng liên kết tài khoản có sẵn trên Ứng Dụng Grab ("Tính Năng Liên Kết") cho mục đích tích lũy Điểm Ngôi Sao theo Chương trình Starbucks Rewards™ này dựa trên chi tiêu của bạn với mỗi đơn hàng được thanh toán.`,
  'termAndCondition.content104': `Để liên kết Tài Khoản Starbucks Rewards™ của ban với Tài Khoản Grab, bạn cần thao tác theo các bước sau trên Ứng Dụng Grab:`,
  'termAndCondition.content105': `1. Truy cập vào phiên bản mới nhất của Ứng Dụng Grab và đăng nhập vào Tài Khoản Grab.`,
  'termAndCondition.content106': `2. Chọn sản phẩm món ăn và đồ uống để đặt hàng: Vào mục “Đồ ăn” trên Ứng Dụng Grab, sau đó bạn có thể tìm kiếm các cửa hàng Starbucks tại thanh “Tìm kiếm”, và chọn cửa Starbucks mà bạn muốn đặt hàng. Cũng giống như tại các cửa hàng của chúng tôi, món ăn và đồ uống có thể tùy chỉnh, bao gồm nhiều tùy chọn để thay đổi các lựa chọn về kích cỡ, số lượng ly cà phê espresso, sữa và các lựa chọn khác. Các món ăn và đồ uống sẽ được cập nhật dựa trên tình trạng sẵn có tại cửa hàng Starbucks được lựa chọn. Bạn có thể xem thông tin từng món và chọn “Thêm vào giỏ hàng” để xác nhận.`,
  'termAndCondition.content107': `3. Xem lại đơn đặt hàng: Sau khi chọn món xong, bạn ấn vào phần “Giỏ hàng” để đi tới trang Giỏ hàng của Ứng Dụng Grab. Tại trang Giỏ hàng, các thông tin chi tiết về đơn hàng của bạn như địa chỉ nhận hàng, số điện thoại nhận hàng và tổng số tiền thanh toán của đơn hàng (bao gồm cả phí vận chuyển) sẽ được hiển thị để bạn xem lại và xác nhận.`,
  'termAndCondition.content108': `4. Liên kết tài khoản: Cũng tại trang “Giỏ hàng”, ban có thể lựa chọn liên kết tài khoản Starbucks Rewards™ của bạn với Tài Khoản Grab cho mục đích tích lũy Điểm Ngôi Sao theo Chương Trình Starbucks Rewards™ bằng cách chọn mục “Membership”, sau đó chọn mục “Add Account”.`,
  'termAndCondition.content109': `Tại đây, trang web của Starbucks sẽ được hiển thị trong Ứng Dụng Grab (in-app browser). Trang web này của Starbucks sẽ hiển thị phần Điều Khoản Sử Dụng này và Chính Sách Bảo Mật Thông Tin của Starbucks  và bạn cần bạn xem xét và chấp thuận. Nếu bạn không chấp thuận Điều Khoản Sử Dụng này và Chính Sách Bảo Mật Thông Tin của Starbucks, bạn sẽ không thể liên kết Tài khoản Starbucks Rewards™ với Tài Khoản Grab. Nếu bạn đã là thành viên của Chương trình Starbucks Rewards™, ấn chọn “Yes” để cung cấp thông tin tài khoản Starbucks Rewards™ của bạn và liên kết hai tài khoản. Nếu bạn chưa là thành viên của Chương Trình Starbucks Rewards™, ấn chọn “No” để đăng ký một tài khoản Starbucks Rewards™ của bạn và tham gia Chương Trình Starbucks Rewards™, mà theo đó tài khoản Starbucks Rewards™ đã đăng ký của bạn sẽ được liên kết với Tài Khoản Grab cho mục đích tích lũy Điểm Ngôi Sao.`,
  'termAndCondition.content110': `Sau khi bạn đã đăng nhập / đăng ký Tài Khoản Starbucks Rewards™ của mình và việc liên kết tài khoản Starbucks Rewards™ của bạn và Tài Khoản Grab đã thành công, bạn sẽ được tự động chuyển hướng về trang “Giỏ hàng” của Ứng Dụng Grab để thanh toán và theo dõi đơn hàng của bạn theo quy định của Ứng Dụng Grab.`,
  'termAndCondition.content111': `Bạn chỉ phải thực hiện một (01) lần các thao tác của Tính Năng Liên Kết để liên kết tài khoản Starbucks Rewards™ của bạn với tài khoản Grab, tức bạn sẽ không phải thực hiện lại các thao tác này cho các đơn hàng tiếp theo sau khi liên kết thành công tài khoản Starbucks Rewards™ của bạn và Tài Khoản Grab.`,
  'termAndCondition.content112': `5. Thông báo số Điểm Ngôi Sao đã được tích luỹ theo từng đơn hàng: Với mỗi đơn hàng mà bạn đã hoàn thành việc thanh toán, Điểm Ngôi Sao sẽ được tự động quy đổi từ giá trị đơn hàng đó và tích lũy vào tài khoản Starbucks Rewards™ của bạn, và bạn sẽ được chuyển sang trang Theo dõi đơn hàng của Ứng Dụng Grab. Tại trang này, bạn sẽ được thông báo rằng liệu bạn có được tích luỹ Điểm Ngôi Sao cho đơn hàng vừa thanh toán hay không. Trong trường hợp được tích luỹ Điểm Ngôi Sao, thì sau khi đơn hàng đã hoàn thành (tức bạn đã nhận được hàng mà bạn đã đặt), bạn sẽ nhận được thông báo như sau: “You’ve earned Stars for this order”. Để kiểm tra số dư Điểm Ngôi Sao của mình, bạn ấn chọn vào biểu tượng “(i)” ngay bên cạnh dòng thông báo, sau đó ấn chọn “Go to Starbucks Vietnam app” để được chuyển hướng sang Ứng Dụng Di Động.`,
  'termAndCondition.content113': `6. Huỷ bỏ liên kết: Bạn có thể huỷ bỏ liên kết giữa tài khoản Starbucks Rewards™ và Tài Khoản Grab bất kỳ lúc nào bằng cách lựa chọn “Remove account” tại phần cài đặt của Ứng Dụng Grab (hình bánh răng trên góc phải màn hình).`,
  'termAndCondition.content114': `Quy Định Khác`,
  'termAndCondition.content115': `Bạn không phải trả phí thành viên cho việc tham gia Chương trình Starbucks Rewards™, và có thể đăng ký trực tuyến tài khoản Starbucks Rewards™ trên Ứng Dụng Di Động để tham gia Chương trình Starbucks Rewards™ với một (01) Thẻ Điện Tử giá trị 0 VNĐ của bạn được đăng ký vào tài khoản sau khi việc đăng ký tài khoản thành công. Tuy nhiên, bạn chỉ có thể sử dụng tính năng O&PU và/hoặc tính năng SBD khi bạn có Thẻ Starbucks đã kích hoạt và đã nạp tiền đủ để thanh toán trong tài khoản của bạn. Bạn cần nạp tối thiếu 100.000 VNĐ để kích hoạt Thẻ Starbucks bằng nhựa của bạn tại cửa hàng. Điểm Ngôi Sao tích lũy trong chương trình không có giá trị quy đổi thành tiền mặt.`,
  'termAndCondition.content116': `Starbucks có thể giới thiệu các chương trình khuyến mại liên quan đến Chương trình Starbucks Rewards™ theo đó các Điều Khoản Sử Dụng này có thể được thay đổi để áp dụng cho các chương trình đó.`,
  'termAndCondition.content117': `Chương trình Starbucks Rewards™ sẽ được triển khai như trong thời hạn đã thông báo cho cơ quan có thẩm quyền và thông báo tại các cửa hàng Starbucks tại Việt Nam. Theo đó, tài khoản Starbucks Rewards™ và tất cả Thẻ Starbucks đã đăng ký của bạn sẽ hết hạn vào ngày kết thúc Chương trình Starbucks Rewards™, do đó tất cả Điểm Ngôi Sao được tích lũy trong tài khoản của bạn sẽ không còn giá trị vào ngày kết thúc Chương trình đó mà Starbucks không chịu bất cứ trách nhiệm nào đối với bạn. Trong trường hợp này, bạn sẽ được thông báo để nhận/quy đổi số dư trong tất cả Thẻ Starbucks đã đăng ký của bạn trong khoảng thời hạn cụ thể sẽ được thông báo cụ thể đến bạn.`,
  'termAndCondition.content118': `Hủy tài khoản`,
  'termAndCondition.content119': `Bạn có thể yêu cầu xóa tài khoản Starbucks® Rewards của mình bất kỳ lúc nào theo quy trình xóa tài khoản được cung cấp tại `,
  'termAndCondition.content120': ` hoặc Ứng Dụng Di Động. Vui lòng lưu ý rằng: (a) Tất cả các yêu cầu xóa tài khoản là cuối cùng và không thể thay đổi khi đã được xử lý; và (b) ngay khi tài khoản của bạn đã bị xóa, tất cả thông tin và quyền lợi liên quan đến tài khoản đã bị xóa của bạn sẽ không thể truy cập hoặc khôi phục, bao gồm cả lịch sử tài khoản, số dư Thẻ Starbucks, Điểm Ngôi Sao và các quyền lợi thưởng khác.`,
  'termAndCondition.content121': `Trừ trường hợp pháp luật có quy định khác, Starbucks sẽ không chấp nhận hoặc không thực hiện bất cứ sự đổi hoặc hoàn trả nào đối với hàng hoá, dịch vụ đã mua tại các cửa hàng Starbucks. Trong trường hợp phải hoàn trả, theo quyết định chỉ riêng của Starbucks, Starbucks bảo lưu quyền trừ các quyền lợi và số Điểm Ngôi Sao đã được tích lũy, kể cả Điểm Ngôi Sao Khuyến Mại, nếu có, tương ứng với giá trị mua của hàng hóa dịch vụ được hoàn trả.`,
  'termAndCondition.content122': `Nếu Thẻ Starbucks của bạn bị mất, bị đánh cắp hoặc bị phá hủy, bạn có thể sẽ mất số dư tiền trong Thẻ Starbucks của bạn. Tuy nhiên, trong trường hợp là thành viên của Chương Trình Starbucks Rewards™, bạn có thể bảo toàn số dư Điểm Ngôi Sao trên tài khoản Starbucks Rewards™ của bạn, nếu bạn thông báo về việc Thẻ bị mất, bị đánh cắp, phá hủy thông qua website `,
  'termAndCondition.content123': ` hoặc Ứng Dụng Di Động hoặc gọi tới Trung Tâm Chăm Sóc Khách Hàng Starbucks Rewards™ tại (028) 7306 7676, và xác nhận chỉ dẫn của bạn. Trong trường hợp bạn chỉ có một Thẻ Starbuck đã đăng ký, bạn có thể gửi cho chúng tôi yêu cầu cấp lại Thẻ Starbucks thay thế , theo đó, tất cả số dư của Thẻ Starbuck Cards bị mất/bị đánh cắp/bị hủy hoại này có thể được chuyển qua Thẻ thay thế và bạn có thể tiếp tục sử dụng Thẻ thay thế này để nhận các ưu đãi chưa sử dụng trong thời hạn có hiệu lực của ưu đãi đó, ngược lại trong trường hợp bạn có nhiều hơn một Thẻ Starbucks đã đăng ký, số dư của Thẻ Starbucks Cards bị mất/bị đánh cắp/bị hủy hoại này sẽ được chuyển đến một trong các Thẻ Starbucks đã đăng ký khác của bạn.`,
  'termAndCondition.content124': `Điểm Ngôi Sao, các Ưu Đãi, (các) Thẻ Starbucks đã đăng ký của bạn, và tài khoản của bạn trong Chương trình Starbucks Rewards™ là dành riêng cho cá nhân bạn và không thể bán, chuyển giao hoặc chuyển nhượng, hoặc chia sẻ với gia đình, bạn bè hoặc những người khác, hoặc sử dụng bởi bạn cho bất cứ mục đích thương mại nào. Bạn được yêu cầu sử dụng địa chỉ email của bạn để đăng ký tài khoản Starbucks Rewards™ dành riêng cho bạn và mỗi địa chỉ email của bạn có thể được sử dụng để đăng ký duy nhất (1) một tài khoản Starbucks Rewards™.`,
  'termAndCondition.content125': `Starbucks bảo lưu quyền “hủy đăng ký” tài khoản Starbucks Rewards™ đối với bất cứ Thẻ Starbucks đã hết hạn hoặc Thẻ Starbucks không hoạt động mà không cần phải thông báo đến bạn. Trong trường hợp Thẻ Starbucks hoặc tài khoản Starbucks Rewards™ của bạn bị hủy đăng ký hoặc bị xem xét là không hoạt động, thì tất cả các Điểm Ngôi Sao được tích lũy trong tài khoản của bạn không có giá trị.`,
  'termAndCondition.content126': `Starbucks bảo lưu quyền đình chỉ bất cứ Thẻ Starbucks đã đăng ký và/hoặc chấm dứt tài khoản Starbucks Rewards™ của bạn và/hoặc sự tham gia của bạn vào Chương trình Starbucks Rewards™ theo quyết định chỉ riêng của Starbucks mà không cần thông báo đến bạn nếu Starbucks xác định rằng bạn đã vi phạm bất kỳ điều khoản nào của Điều khoản Sử dụng này và các điều khoản được kết hợp bằng việc tham chiếu, hoặc một địa chỉ email của bạn được sử dụng để đăng ký cho nhiều hơn một (1) tài khoản Starbucks Rewards™, hoặc việc sử dụng tài khoản Starbucks Rewards™ của bạn là trái phép, lừa đảo, gian lận hoặc trái pháp luật. Theo quyết định chỉ riêng của Starbucks, Starbucks có thể đình chỉ, hủy bỏ hoặc kết hợp các tài khoản thể hiện như là bản sao của nhau. Trong trường hợp sự tham gia của bạn vào Chương trình Starbucks Rewards™ bị chấm dứt, thì tất cả các Điểm Ngôi Sao được tích lũy trong tài khoản của bạn không có giá trị, và tất cả số dư trong Thẻ Starbucks đã đăng ký của bạn sẽ trở thành doanh thu của Starbucks mà Starbucks không phải chịu bất kỳ trách nhiệm nào đối với chủ Thẻ Starbucks.`,
  'termAndCondition.content127': `Tất cả các khiếu nại hoặc tranh chấp phát sinh từ hoặc liên quan đến các Điều Khoản Sử Dụng hoặc Chương trình Starbucks Rewards™ này™ sẽ được giải quyết theo pháp luật được áp dụng tại Việt Nam.`,
  'termAndCondition.content128': `Nếu bạn có bất cứ thắc mắc liên quan đến Chương trình Starbucks Rewards™, xin vui lòng liên hệ tới Công ty TNHH Thực phẩm & Nước giải khát Ý Tưởng Việt, bằng cách gửi thư tới Tầng 14, Tòa nhà Phượng Long 2, 16 Nguyễn Trường Tộ, Phường 13, Quận 4, Thành phố Hồ Chí Minh hoặc thông qua Trung tâm Chăm sóc Khách hàng Starbucks Rewards™ số (028) 7306 7676.`,
};
