import { CircularProgress } from '@mui/material';

import './styles.scss';

const GlobalLoading = () => {
  return (
    <div className="globalLoading">
      <CircularProgress className="spin" color="error" />
    </div>
  );
};

export default GlobalLoading;
