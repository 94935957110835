export default {
  'login.title': 'Liên kết tài khoản Starbucks Rewards™ của bạn với Grab',
  'login.title.zalo': 'Liên kết tài khoản Starbucks Rewards™ của bạn với ZaloPay',
  'login.title.momo': 'Liên kết tài khoản Starbucks Rewards™ của bạn với MoMo',
  'login.title1': 'Liên kết tài khoản Starbucks ',
  'login.title2': 'Rewards của bạn với',
  'login.title3': 'Grab',
  'login.title.email': 'Email',
  'login.title.password': 'Mật khẩu',
  'login.title.forgotPassword': 'Quên mật khẩu?',
  'login.title.link': 'Liên kết ngay',
  'login.error.empty.email': 'Vui lòng nhập thông tin bắt buộc',
  'login.error.invalid.email': 'Vui lòng nhập email hợp lệ',
  'login.error.empty.password': 'Vui lòng nhập thông tin bắt buộc',
  'login.error.link.fail': 'Liên kết không thành công',
  'login.error.grab.not.avalaible': `Đã xảy ra lỗi không mong muốn. Vui lòng thử lại sau`,
  'login.error.grab.not.avalaible.unseccessfully': 'Không thành công',
};
