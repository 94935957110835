import forgotPassword from './en/forgotPassword';
import forgotPasswordSuccess from './en/forgotPasswordSuccess';
import global from './en/global';
import login from './en/login';
import signUp from './en/signUp';
import successLinkPage from './en/successLinkPage';
import termAndCondition from './en/termAndCondition';
import welcome from './en/welcome';

export default {
  login: login,
  termAndCondition: termAndCondition,
  successLinkPage: successLinkPage,
  signUp: signUp,
  welcome: welcome,
  forgotPassword: forgotPassword,
  forgotPasswordSuccess: forgotPasswordSuccess,
  global: global,
};
