export default {
  'signUp.title.joinNow': 'Join Starbucks Rewards™',
  'signUp.title.field.error.required': 'Please input the required fields',
  'signUp.title.field.error.no.vn.char': 'Please do not use Vietnamese with accents',
  'signUp.title.field.error.agree.term':
    'Kindly agree to the privacy policy and terms of use to complete your registration',
  'signUp.title.field.error.valid.email': 'Please enter a valid email address',
  'signUp.title.field.error.betweet.8.15': 'Betweeen 8 and 15 characters',
  'signUp.title.field.error.at.least.1': 'At least one number',
  'signUp.title.field.error.at.least.1.capital': 'At least one capital letter',
  'signUp.title.field.error.at.least.1.lowercase': 'At least one lowercase letter',
  'signUp.title.field.error.at.least.1.special.character':
    'At least 1 special character (e.g.!., @, #, $, %, ^)',
  'signUp.title.field.error.at.least.no.special.character':
    'Please do not input special character (eg:!, @, #, $, %, ^, &, *)',
  'signUp.title.loginDetail': 'Login Details',
  'signUp.title.field.error.password.not.match': 'Password do not match',
  'signUp.title.field.error.birthday.required': 'Please input required fields',
  'signUp.title.field.error.birthday.month.required': 'Please fill in your birthday',
  'signUp.title.field.error.invalid.date': 'Invalid date',
  'signUp.title.field.error.9.to.10.degit': 'please enter 9 or 10 digits',
  'signUp.title.field.error.invalid.phoneNumber': 'Please enter a valid phone number',
  'signUp.title.email': 'Email',
  'signUp.title.password': 'Password',
  'signUp.title.confirmPassword': 'Confirm Password',
  'signUp.title.personalInfo': 'Personal Information',
  'signUp.title.salutation': 'Salutation',
  'signUp.title.firstName': 'First Name',
  'signUp.title.firstName.note': 'Please note that do not use Vietnamese with accents',
  'signUp.title.lastName': 'Last Name',
  'signUp.title.lastName.note': 'Please note that do not use Vietnamese with accents',
  'signUp.title.birthday': 'Birthday',
  'signUp.title.birthday.note': "Let us know your birthday so you won't miss a birthday treat",
  'signUp.title.month': 'Month',
  'signUp.title.date': 'Date',
  'signUp.title.mobileNumber': 'Mobile Number',
  'signUp.title.countryCode': 'Country Code',
  'signUp.title.termTitle': 'Preferences & terms',
  'signUp.title.term1': 'I wish to receive news, special offers and more from Starbucks.',
  'signUp.title.term6': 'Starbucks Vietnam mobile app ',
  'signUp.title.term7': 'or ',
  'signUp.title.term8': "member's website.",
  'signUp.title.term2': 'I have read and agreed to Starbucks Vietnam ',
  'signUp.title.term3': 'Privacy Policy',
  'signUp.title.term4': 'and',
  'signUp.title.term5': 'Terms of Use',
  'signUp.title.button.joinNow': 'Join now',
  'signUp.title.button.option.none': 'None',
  'signUp.salutation.option.mr': 'Mr',
  'signUp.salutation.option.mrs': 'Mrs',
  'signUp.salutation.option.ms': 'Ms',
};
