import { ROUTE_PATH } from './constant';
import { lazy } from 'react';
import { Route } from 'types/index';

const HomePage = lazy(() => import('pages/Homepage'));
const Login = lazy(() => import('pages/Login'));
const TermsAndCondition = lazy(() => import('pages/TermsAndCondition'));
const SuccessLinkPage = lazy(() => import('pages/SuccessLinkPage'));
const SignUp = lazy(() => import('pages/SignUp'));
const Welcome = lazy(() => import('pages/Welcome'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('pages/ForgotPasswordSuccess'));

export const routes: Route[] = [
  {
    path: ROUTE_PATH.HOME,
    component: HomePage,
    exact: true,
    pageTitle: 'HomePage',
    key: 'HomePage',
    checkLogin: true,
  },
  {
    path: ROUTE_PATH.LOGIN,
    component: Login,
    pageTitle: 'Login',
    key: 'Login',
  },
  {
    path: ROUTE_PATH.SIGN_UP,
    component: SignUp,
    pageTitle: 'Sign up',
    key: 'SignUp',
  },
  {
    path: ROUTE_PATH.TERMS_AND_CONDITION,
    component: TermsAndCondition,
    pageTitle: 'Terms and condition',
    key: 'TERMS_AND_CONDITION',
  },
  {
    path: ROUTE_PATH.SUCCESS_LINK_PAGE,
    component: SuccessLinkPage,
    pageTitle: 'Success link page',
    key: 'SUCCESS_LINK_PAGE',
  },
  {
    path: ROUTE_PATH.WELCOME,
    component: Welcome,
    pageTitle: 'Welcome',
    key: 'WELCOME',
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD,
    component: ForgotPassword,
    pageTitle: 'ForgotPassword',
    key: 'ForgotPassword',
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD_SUCCESS,
    component: ForgotPasswordSuccess,
    pageTitle: 'ForgotPasswordSuccess',
    key: 'ForgotPasswordSuccesss',
  },
];
