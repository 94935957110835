import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { hasLogin } from 'utils/utils';
import { ROUTE_PATH } from 'utils/constant';
import './styles.scss';

type PrivateRouteProps = {
  // pageTitle: string;
  // path: string;
  children: React.ReactElement;
  pageTitle: string;
};

const PrivateRoute = ({ children, pageTitle }: PrivateRouteProps) => {
  const { appName } = useParams();
  if (!hasLogin()) {
    return <Navigate to={`/${appName}${ROUTE_PATH.TERMS_AND_CONDITION}`} replace />;
  }

  return (
    <div className="privateRoute">
      <div className="privateRoute__header">
        <p className="privateRoute__header--title">{pageTitle}</p>
      </div>
      {children}
    </div>
  );
};

export default PrivateRoute;
