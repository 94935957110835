export default {
  'successLinkPage.allSet': 'All set',
  'successLinkPage.sub.title': 'Sending you back to Grab app to earn more Stars',
  'successLinkPage.note': 'for orders via Grab*',
  'successLinkPage.more.detail1': 'Check out more details and rewards on ',
  'successLinkPage.more.detail2': 'Starbucks Vietnam mobile app',
  'successLinkPage.more.detail3': ' or ',
  'successLinkPage.sub.note1': 'Free drink for every 25 Stars (Gold exclusive)',
  'successLinkPage.sub.note2': 'Birthday treats on the house',
  'successLinkPage.sub.note3': 'Exclusive member offers',
};
