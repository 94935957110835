export default {
  'signUp.title.joinNow': 'Tham gia Starbucks Rewards™',
  'signUp.title.field.error.required': 'Vui lòng nhập thông tin bắt buộc',
  'signUp.title.field.error.no.vn.char': 'Vui lòng không sử dụng tiếng Việt có dấu',
  'signUp.title.field.error.agree.term':
    'Vui lòng đồng ý với chính sách bảo mật và điều khoản sử dụng để hoàn tất đăng ký của bạn',
  'signUp.title.field.error.valid.email': 'Vui lòng nhập địa chỉ email hợp lệ',
  'signUp.title.field.error.betweet.8.15': 'Từ 8 đến 15 ký tự',
  'signUp.title.field.error.at.least.1': 'Ít nhất một chữ số',
  'signUp.title.field.error.at.least.1.capital': 'Ít nhất một chữ cái viết hoa',
  'signUp.title.field.error.at.least.1.lowercase': 'Ít nhất một chữ cái viết thường',
  'signUp.title.field.error.at.least.1.special.character':
    'Ít nhất 1 ký tự đặc biệt (e.g.!., @, #, $, %, ^)',
  'signUp.title.field.error.at.least.no.special.character':
    'Vui lòng không dùng ký tự đặc biệt (eg:!, @, #, $, %, ^, &, *)',
  'signUp.title.loginDetail': 'Thông tin đăng nhập',
  'signUp.title.field.error.password.not.match': 'Mật khẩu không khớp',
  'signUp.title.field.error.birthday.required': 'Vui lòng nhập thông tin bắt buộc',
  'signUp.title.field.error.birthday.month.required': 'Vui lòng nhập ngày sinh của bạn',
  'signUp.title.field.error.invalid.date': 'Ngày không hợp lệ',
  'signUp.title.field.error.9.to.10.degit': 'Vui lòng nhập 9 hoặc 10 chữ số',
  'signUp.title.field.error.invalid.phoneNumber': 'Vui lòng nhập số điện thoại hợp lệ',
  'signUp.title.email': 'Email',
  'signUp.title.password': 'Mật khẩu',
  'signUp.title.confirmPassword': 'Xác nhận mật khẩu',
  'signUp.title.personalInfo': 'Thông tin cá nhân',
  'signUp.title.salutation': 'Danh xưng',
  'signUp.title.firstName': 'Tên',
  'signUp.title.firstName.note': 'Xin lưu ý không sử dụng tiếng Việt có dấu',
  'signUp.title.lastName': 'Họ',
  'signUp.title.lastName.note': 'Xin lưu ý không sử dụng tiếng Việt có dấu',
  'signUp.title.birthday': 'Sinh nhật',
  'signUp.title.birthday.note':
    'Một món quà nhỏ sẽ được gửi vào tài khoản của bạn trong dịp đặc biệt này.',
  'signUp.title.month': 'Tháng',
  'signUp.title.date': 'Ngày',
  'signUp.title.mobileNumber': 'Số điện thoại',
  'signUp.title.countryCode': 'Mã quốc gia',
  'signUp.title.termTitle': 'Tùy chọn nhận thông tin khuyến mãi',
  'signUp.title.term1': 'Tôi muốn nhận thông tin khuyến mãi mới từ Starbucks Việt Nam.',
  'signUp.title.term6': 'ứng dụng di động Starbucks ',
  'signUp.title.term7': 'hoặc ',
  'signUp.title.term8': 'trang web của thành viên Starbucks Việt Nam.',
  'signUp.title.term2': 'Tôi đã đọc và đồng ý với ',
  'signUp.title.term3': 'Chính sách về quyền riêng tư',
  'signUp.title.term4': 'của Starbucks Việt Nam và',
  'signUp.title.term5': 'Điều kiện & Điều khoản chương trình',
  'signUp.title.button.joinNow': 'Đăng ký',
  'signUp.title.button.option.none': 'Không',
  'signUp.salutation.option.mr': 'Ông',
  'signUp.salutation.option.mrs': 'Bà',
  'signUp.salutation.option.ms': 'Cô',
};
