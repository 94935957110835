export default {
  'login.title': 'Link your Starbucks Reward™ account with Grab',
  'login.title.zalo': 'Link your Starbucks Rewards™ account with ZaloPay',
  'login.title.momo': 'Link your Starbucks Rewards™ account with MoMo',
  'login.title1': 'Link your Starbucks ',
  'login.title2': 'Rewards account with ',
  'login.title3': 'Grab',
  'login.title.email': 'Email',
  'login.title.password': 'Password',
  'login.title.forgotPassword': 'Forgot password?',
  'login.title.link': 'Link now',
  'login.error.empty.email': 'Please input the required fields',
  'login.error.invalid.email': 'Please enter a valid email address',
  'login.error.empty.password': 'Please input the required fields',
  'login.error.link.fail': 'Link failed',
  'login.error.grab.not.avalaible': 'There was an unexpected error. Please try again later',
  'login.error.grab.not.avalaible.unseccessfully': 'Link unseccessfully',
};
