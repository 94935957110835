import { COOKIE_NAMES } from './constant';

export const setCookie = (cookieName: string, cookieValue: string, date: Date): void => {
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

export const getCookie = (cookieName: string): string => {
  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  if (match) return match[2].replace(/[']+/g, '');
  return '';
};

export const hasLogin = () => !!getCookie(COOKIE_NAMES.REFRESH_TOKEN);

export const getValidDateByMonth = (month: number) => {
  const numberDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const listDate = [];
  const numberOfValidDate = numberDaysInMonth?.[month - 1] || 31;
  for (var i = 1; i <= numberOfValidDate; i++) {
    listDate.push(i);
  }
  return listDate;
};
