export const ROUTE_PATH = {
  HOME: '/',
  LOGIN: '/login',
  TERMS_AND_CONDITION: '/terms-and-condition',
  SUCCESS_LINK_PAGE: '/success-link-page',
  SIGN_UP: '/sign-up',
  WELCOME: '/welcome',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_SUCCESS: '/forgot-password-success',
};

export const LOCAL_STORAGE_NAMES = {
  CURRENT_LANGUAGE: 'languages',
};

export const COOKIE_NAMES = {
  ACCESS_TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken',
};

export const REGEX = {
  AT_LEAST_UPPERCASE: /(?=.*[A-Z])/,
  AT_LEAST_LOWERCASE: /(?=.*[a-z])/,
  AT_LEAST_ONE_DIGIT: /(?=.*[0-9])/,
  AT_LEAST_ONE_SPECIAL_CHARACTER: /(?=.*\W)/,
  BETWEEN_8_TO_15_CHARACTER: /^.{8,15}$/,
  PHONE_NUMBER: /^(?:0)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
  NO_VN_CHAR: /^[\u0000-\u007F ]*$/,
  // eslint-disable-next-line
  SPECIAL_CHARACTER: /[`!@#$%^&*+\=\[\]{};:\\|<>\/?~.]/,
};

export const MONTH_LIST = [
  {
    label: 'January',
    labelVI: 'Tháng 1',
    value: 1,
  },
  {
    label: 'February',
    labelVI: 'Tháng 2',
    value: 2,
  },
  {
    label: 'March',
    labelVI: 'Tháng 3',
    value: 3,
  },
  {
    label: 'April',
    labelVI: 'Tháng 4',
    value: 4,
  },
  {
    label: 'May',
    labelVI: 'Tháng 5',
    value: 5,
  },
  {
    label: 'June',
    labelVI: 'Tháng 6',
    value: 6,
  },
  {
    label: 'July',
    labelVI: 'Tháng 7',
    value: 7,
  },
  {
    label: 'August',
    labelVI: 'Tháng 8',
    value: 8,
  },
  {
    label: 'September',
    labelVI: 'Tháng 9',
    value: 9,
  },
  {
    label: 'October',
    labelVI: 'Tháng 10',
    value: 10,
  },
  {
    label: 'November',
    labelVI: 'Tháng 11',
    value: 11,
  },
  {
    label: 'December',
    labelVI: 'Tháng 12',
    value: 12,
  },
];

export const DATE = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31,
];
export const LANGUAGE = {
  VI: 'VI',
  EN: 'EN',
};
export const STARBUCKS_LINK = {
  PRIVACY_POLICY_EN:
    'https://en.starbucks.vn/about-us/company-information/online-policies/privacy-statement/',
  PRIVACY_POLICY_VI:
    'https://www.starbucks.vn/ve-chung-toi/cong-ty/chinh-sach-truc-tuyen/chi-nh-sa-ch-ba-o-ma-t-thong-tin/',
  TERM_OF_USE_EN:
    'https://en.starbucks.vn/about-us/company-information/online-policies/terms-of-use/',
  TERM_OF_USE_VI:
    'https://www.starbucks.vn/ve-chung-toi/cong-ty/chinh-sach-truc-tuyen/dieu-khoan-su-dung/',
  HOME_VI: 'https://card.starbucks.vn/vi/',
  HOME_EN: 'https://card.starbucks.vn/en/',
};

export const APP_NAME = {
  GRAB: 'grab',
  ZALO: 'zalo',
  MOMO: 'momo',
};
